@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.side_panel {
  display: none;

  &.no-padding {
    .side_panel__content {
      padding: 0;
    }
  }

  &__overlay {
    position: fixed;
    inset: 0;
    z-index: 99999;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: rgba(0, 0, 0, 60%);
  }

  &__container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    background-color: var(--c_white);

    &--height-fit {
      height: fit-content;
    }

    &--slide-top {
      position: relative;
      bottom: 0;
      margin-top: auto;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 8%;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 10%);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%;
    max-height: 100%;
    padding: 25px 20px;
    overflow-y: auto;

    .title {
      color: #000;
    }

    .side_panel__iframe {
      width: 100%;
      height: 100%;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12%;
    padding: 20px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 10%);

    .btn-floating-fastbooking {
      z-index: 2;
      width: 100%;
    }
  }

  &__close {
    @include icon(arrow-left-fat, before) {
      padding: 0;
      border-radius: 0;
    }
    z-index: 2;
    padding: 20px 15px;
    border: 0;
    background: transparent;
  }

  &.is-open {
    display: block;
  }

  &.has_iframe {
    .side_panel__content {
      padding: 0;
    }
  }

  &[aria-hidden='false'] .side_panel__overlay {
    animation: side-fade-in 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &[aria-hidden='false'] .side_panel__container {
    animation: side-slide-in 0.3s cubic-bezier(0, 0, 0.2, 1);

    &--slide-top {
      animation: side-slide-top-in 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &[aria-hidden='true'] .side_panel__overlay {
    animation: side-fade-out 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &[aria-hidden='true'] .side_panel__container {
    animation: side-slide-out 0.3s cubic-bezier(0, 0, 0.2, 1);

    &--slide-top {
      animation: side-slide-top-out 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .awards-list {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .award-item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;

      &.hidden {
        display: none;
      }

      .award-image {
        width: calc(100% / 3);

        .award-image-item {
          width: 80%;

          img {
            width: 100%;
          }
        }
      }

      .award-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: calc((100% * 2) / 3);

        .award-title {
          @include fontSize(18);
        }

        .award-description {
          color: #7F7F7F;
        }
      }
    }
  }

  .awards-show-more {
    z-index: 2;
  }
}
