@use 'cards_hotels_commons' as *;
@use '../../../elements/radio_btn/radio_btn_mobile';

.card.hotel {
  @include tripadvisor-personalization;

  .offer-pill {
    margin-bottom: 5px;
  }

  .badges-list {
    width: auto;
  }

  //TODO: PY05649-639: ibercuba
  .badges-list.ocean.ibercuba {
    .badge.ocean {
      background-color: var(--c_white) !important;
      color: var(--c_primary);
    }
  }

  .info-links {
    display: none;
  }

  .info-box-services {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in;

    &.active {
      max-height: 500px;
    }
  }

  .card-container {
    grid-template-areas:
      'segment'
      'text'
      'myibe'
      'discounts'
      'cta';
    grid-template-columns: 1fr;

    .text-header {
      .show-map {
        z-index: 9;
        margin-left: 4px;
        font-size: 12px;
      }
    }

    .text-summary,
    .action-share {
      margin-left: 10px;
      margin-right: 10px;
    }

    .badges-list {
      margin-bottom: 0;
    }

    .discounts,
    .myibe-discount {
      flex-direction: row;
      justify-content: flex-start;
      padding: 0;

      .offer-pill {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }

  .text-summary {
    font-size: 12px;
  }

  hr {
    clear: both;
    width: 100%;
    border: 0;
    border-bottom: 1px solid var(--c_grey_200);
  }

  .tripadvisor-info {
    position: relative;

    &.mobile-style {
      margin: 10px;
    }
  }

  .more-info {
    padding: 0;
    border: 0;
    background: var(--c_white);
    font-size: 12px;
    line-height: 18px;

    &.hidden {
      display: none;
    }

    .closed {
      display: none;
    }

    &.active {
      .closed {
        display: block;
      }

      .opened {
        display: none;
      }
    }
  }

  .call-to-action {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    .accommodation {
      flex-grow: 1;
      justify-content: flex-end;
      margin-bottom: 5px;
    }

    .price-cnt.price-before {
      flex-grow: 1;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .btn-floating-fastbooking {
      z-index: 9;
      width: 100%;
      margin-top: 8px;
    }
  }

  &.horizontal-card {
    .card-container {
      .text {
        margin-bottom: 0;

        .opinion-widget {
          position: relative;
          transform: translateY(0);
        }
      }

      .call-to-action {
        padding: 0;

        .hint-cnt {
          margin-bottom: 0;
        }
      }
    }
  }

  .opinion-widget {
    float: right;
    margin-top: 5px;

    .tripadvisor-widget {
      .widget {
        display: flex;

        .tripadvisor-logo {
          max-width: 84px;
          height: 18px;
        }

        .tripadvisor-open-fancy {
          margin-left: 6px;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    .holidaycheck-widget {
      padding-bottom: 0;

      div.hc-widget.hc-widget-small {
        width: 76px;

        div.hc-widget-body {
          height: auto;
          max-height: 61px;
          padding-top: 0;

          img {
            width: 42px;
            height: auto;
            padding: 0;
            margin: 0;
          }

          div.hc-widget-content-box {
            padding-bottom: 4px;

            span.hc-widget-rating {
              padding-top: 2px;
              font-size: 11px;
              line-height: 14px;
            }

            span.hc-widget-rating-subline {
              font-size: 6px;
              line-height: 8px;
            }

            span.hc-widget-hotel-name {
              padding-top: 1px;
              font-size: 6px;
              line-height: 8px;
            }
          }
        }
      }
    }
  }

  .pills-cnt {
    margin: 0;

    ul {
      li {
        z-index: 99;
      }
    }
  }

  .image-link,
  .hotel-image {
    max-height: 132px;
    overflow: hidden;
  }

  .hotel-image {
    &::before {
      max-height: 132px;
    }
  }

  .footer {
    &__disclaimer {
      padding: 15px;
      font-size: 12px;
    }
  }

  .disclaimer {
    &__hotel-card {
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: space-between;

      .s-alert-info::after {
        font-size: 20px;
      }
    }

    &__logo-ihg {
      width: 35%;

      .img-logo {
        width: 100%;
      }
    }

    &__text {
      width: 90%;
      margin-left: 0;
    }
  }
}

.slider-container {
  position: relative;
  max-height: 143px;

  .ss-slide-hotel {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 0 2px 0 0;
    scroll-snap-align: start;

    .image-link {
      max-height: 143px;
    }

    .open-link {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      display: none;
      max-width: 250px;
      padding: 9px 12px;
      margin: 0 auto;

      .s {
        padding-right: 10px;

        &::first-line {
          @include fontSize(24px);
        }
      }
    }

    &.last {
      position: relative;
      z-index: 11;

      .open-link {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        filter: brightness(0.5);
      }
    }
  }

  .hotel-image-in-slider {
    img {
      width: 100%;
      height: 143px;
    }
  }

  .ss-slider-nav {
    position: absolute;
    left: 0;
    bottom: 5px;
    right: 0;
    z-index: 11;
    text-align: center;

    .radio-btn:not(.active) {
      opacity: 0.5;
      background: var(--c_white);
    }
  }
}

.card.hotel-light {
  .price-cnt {
    flex-direction: column;
  }
}
