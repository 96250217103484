@use 'info_item_commons' as *;

.info-items {
  padding: 0 20px;
  overflow: hidden;

  .wrapper {
    padding: 0;
  }
}

.info-item {
  width: 100%;

  .content {
    padding: 20px;
  }

  .text {
    margin-bottom: 10px;
  }

  .t-highlight {
    margin-bottom: 10px;
  }
}
