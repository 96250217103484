@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.offer-block {
  position: relative;
  display: flex;
  border: 1px solid rgba(227, 233, 245, 50%);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 24%);

    .arrow-container {
      background-color: var(--c_blue_green_100);
    }
  }

  .full-link {
    position: absolute;
    inset: 0;
    z-index: 10;
  }

  .card-image {
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
    }
  }

  .container {
    display: flex;
    flex: 0 1 100%;
    flex-direction: column;

    > *:not(.bottom-content) {
      flex: 0 0 auto;
    }

    .pre-title {
      margin-bottom: 5px;
    }

    .title {
      display: inline-block;
      padding-right: 25px;
      margin-top: 10px;
      color: var(--c_dark_grey);

      .text-title {
        display: inline;
        margin-right: 5px;
      }

      .stars {
        display: inline;

        .rating-stars {
          &::before {
            margin-bottom: 5px;
          }
        }
      }
    }

    .bottom-content {
      display: flex;
      flex: 0 1 100%;
      justify-content: space-between;

      > * {
        align-self: flex-end;
      }

      .fake-link {
        position: relative;
        z-index: 15;
        flex: 0 1 auto;
      }

      [data-tooltip],
      [data-discounttooltip] {
        z-index: 15;
      }

      .price-info {
        flex: 1 0 auto;

        .prev-type {
          @include fontSansSemiBold;
          @include fontSize(12);
          color: var(--c_carrot);
          line-height: 18px;
        }

        .prev-highlight {
          position: relative;
          display: inline-block;

          &::after {
            position: absolute;
            left: 0;
            bottom: 10px;
            content: '';
            width: 100%;
            height: 1px;
            background: var(--c_carrot);
          }

          .t-price,
          .t-coin {
            @include fontSansSemiBold;
            @include fontSize(12);
            color: var(--c_carrot);
            line-height: 18px;
          }
        }

        .hint-cnt {
          @include fontSize(12);
          @include fontSansSemiBold;
          line-height: 18px;
        }
      }
    }
  }

  .arrow-container {
    @include icon(arrow-right-fat, after, 'xs') {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--c_primary);
    }
    position: relative;
    flex: 0 0 24px;
    border-left: 1px solid rgba(227, 233, 245, 50%);
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
}

@include mobileStyles {
  .offer-block {
    flex: 0 0 100%;
    margin-bottom: 5px;

    .card-image {
      flex: 0 0 23.124%;
    }

    .container {
      padding: 10px;

      .pre-title {
        margin-bottom: 2px;
      }

      .title {
        @include fontSize(14);
        margin-bottom: 8px;
        line-height: 21px;
      }

      .pre-title {
        @include fontSize(10);
        line-height: 18px;
        letter-spacing: 1.29px;
      }

      .bottom-content {
        .price-info {
          .type,
          .highlight {
            display: inline-block;
          }

          .t-coin,
          .t-price,
          .type {
            @include fontSansSemiBold;
            @include fontSize(16);
            color: var(--c_blue_700);
            line-height: 22px;
            text-transform: uppercase;
          }

          .hint-cnt {
            display: none;
          }
        }
      }
    }

    .fake-link {
      display: none;
    }
  }

  .mobile-offers-filter-link {
    @include link-base;

    @include icon(filter, after, 'm') {
      position: absolute;
      top: 50%;
      left: 24px;
      transform: translateY(-50%);
    }
    display: block;
    padding: 10px 10px 10px 56px;
    margin-bottom: 5px;
    background: var(--c_blue_green_100);

    &::before {
      display: none;
    }
  }
}
