@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.not-available-message {
  margin-bottom: 10px;
  background: var(--c_white);

  ul {
    display: block;

    li {
      position: relative;
      display: block;
      max-width: 180px;
      padding-left: 25px;
      margin: 10px 0;
      color: var(--c_dark_grey);
      font: 500 12px/14px var(--font-sans);
      white-space: normal;
      text-align: left;

      a {
        color: var(--c_blue_700);
      }

      &.not-available {
        @include icon(calendar-times, after, 's') {
          position: absolute;
          top: 5px;
          left: 0;
          color: var(--c_danger);
        }
      }

      &.phone {
        @include icon(phone, after, 's') {
          position: absolute;
          top: 5px;
          left: 0;
          color: var(--c_blue_700);
        }
      }
    }
  }
}

.list {
  .not-available-message {
    display: inline-block;
    border: 0;
    background: transparent;

    ul {
      padding: 0;

      li {
        padding: 0;
        color: var(--c_blue_700);
        font-size: 14px;
        font-family: var(--font-serif);
        font-style: italic;
        line-height: 1.4;

        &::after {
          display: none;
        }

        &.phone {
          display: none;
        }
      }
    }
  }
}

@include media($mobile) {
  .not-available-message {
    ul {
      li {
        float: right;
        max-width: 200px;
        font-size: 14px;

        &.phone {
          display: none;
        }
      }
    }
  }
}
