@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.timing-bar {
  display: flex;
  height: 46px;
  overflow: hidden;
  background: var(--c_dark_grey);

  .timing-content {
    display: flex;
    flex: 0 0 100%;
    align-self: center;
    padding: 0 11px;
    color: var(--c_white);
    text-align: center;

    .days,
    .hours,
    .mins,
    .secs {
      @include fontSansSemiBold;
      @include fontSize(18);
      display: flex;
      flex: 0 0 auto;
      padding-right: 16px;
      line-height: 26px;

      .text {
        visibility: hidden;
        width: 10px;
        margin-left: 8px;

        &::first-letter {
          @include fontSize(12);
          visibility: visible;
          line-height: 18px;
          letter-spacing: 1.54px;
          text-transform: uppercase;
        }
      }
    }

    .days {
      padding-right: 8px;
      margin-right: 8px;
      border-right: 1px solid var(--c_white);
    }

    .secs {
      padding-right: 0;
    }
  }

  span {
    display: block;
    font-weight: 200;
    font-size: 26px;
  }

  .btn-arrow {
    display: inline-block;
    float: right;
    max-width: 205px;
    padding: 12px 15px 12px 34px;
    background: url('../../../images/icons/arrow-yellow.svg') no-repeat left 0;
    background-size: cover;
    color: var(--c_dark_grey);
    font-size: 16px;
    font-style: italic;
    line-height: 18px;
    text-align: left;
    vertical-align: middle;

    span {
      display: inline-block;
      font-size: 16px;
    }

    &.hidden {
      display: none;
    }
  }

  &.red {
    background-image: linear-gradient(#9C1515, #DA0F0F);

    .btn-arrow {
      background: url('../../../images/icons/arrow-red.svg') no-repeat left 0;
      color: var(--c_white);
    }
  }
}
