@use 'badge_commons' as *;

.badge {
  padding-left: 8px;
  padding-right: 8px;

}

//TODO: PY05649-639: ibercuba
.badges-list.ocean.ibercuba {
  .badge.ocean {
    background-color: var(--c_white) !important;
    color: var(--c_primary);
  }
}

.badges-content {
  .badges-item:not(:last-child) {
    margin-bottom: 30px;
  }

  .l-external {
    @include fontSize(14);

    &::after {
      position: relative;
      top: 1px;
      margin-top: 0;
      font-size: 10px;
    }
  }

  .block-title {
    flex-direction: column;
    margin: 0 0 8px;

    .l-external {
      align-self: left;
      white-space: normal;
    }
  }

  /* stylelint-disable-next-line selector-max-type */
  p {
    margin: 0 0 8px;
  }
}
