@use '../../../src/base_css/abstracts/abstracts' as *;
@forward '../../../src/base_css/abstracts/abstracts';

.message-hotels-not-found {
  @include icon(404) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: var(--c_blue_700);
  }
  position: relative;
  max-width: 570px;
  text-align: center;

  .message-title {
    @include fontSerifBold;
    color: var(--c_blue_700);
    line-height: 36px;
  }

  .message-desc {
    opacity: 0.8;
  }
}
