@use 'fidelization_info_commons' as *;

.fidelization-info {
  .block-container {
    padding: 15px 20px 20px;
    margin: 165px 24px 0;

    .pre-title {
      margin-bottom: 9px;
    }

    .block-link {
      width: 100%;
    }

    .horizons-links-content {
      flex-direction: column;

      .button-container {
        .btn-primary {
          width: 100%;
        }
      }

      .link-container {
        padding: $space4mob 0;
      }
    }
  }

  .block-image {
    display: block;

    .image {
      min-width: 100%;
    }
  }
}
