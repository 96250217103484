@use '../../base_css/abstracts/abstracts' as *;

#footer-sticky {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 14;
  display: none;
  width: 100%;
  height: 337px;
  overflow: hidden;
  outline: none;
  background-color: var(--c_white)f;
  transition: height $ani-default-time $ani-default-cubic;

  [data-action] {
    cursor: pointer;
  }

  .toolbar {
    display: flex;
    height: 40px;
    background-color: var(--c_grey_100);

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .action {
      font-weight: bold;

      .hide,
      .show {
        color: var(--c_primary);

        &::after {
          color: var(--c_primary);
        }
      }

      .hide {
        @include icon(arrow-down) {
          padding: 0 0 3px 5px;
          font-weight: 600;
        }
        padding: 2px 0;
      }

      .show {
        @include icon(arrow-up) {
          padding: 0 0 3px 5px;
          font-weight: 600;
        }
        display: none;
      }
    }


  }

  .content {
    background-color: var(--c_white)f;

    .wrapper {
      $bg-top: 9px;
      $bg-side-margin: 8px;
      display: flex;
      flex-direction: column;
      min-height: 235px;
    }

    .cards {
      .comp-card-footer {
        .badges-list {
          display: flex;

          .badge {
            padding: 3px 5px;
            margin-right: 5px;
          }
        }

        .text-header {
          margin: 0;

          .rating-stars {
            display: inline-block;

            &::before {
              margin-bottom: 5px;
            }
          }
        }

        .e-selected-dates {
          margin: 2px 0 0;
        }

        .night-person {
          margin: 0;
          text-transform: capitalize;
        }

        &:first-child,
        &:nth-child(2) {
          margin-right: 31px;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: auto;

      > * {
        display: flex;
        flex: 0 0 33.33%;
        justify-content: center;

        &:first-child {
          justify-content: flex-start;
        }

        &:last-child {
          align-self: center;
        }
      }

      .action {
        .button-stop-comparer {
          padding: 15px 0;
          color: var(--c_primary);
          font-weight: 700;
          transition: color 0.3s ease;

          &:hover {
            color: var(--c_blue_700);
          }
        }

        .button-start-comparer {
          margin-left: 24px;

          &.disabled {
            display: none;
          }
        }
      }
    }
  }

  .cards {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-top: 22px;
  }

  .wide-message {
    position: absolute;
    z-index: 1;
    width: 100%;
    text-align: center;

    .msg-title {
      margin: 0 0 16px;
      color: var(--c_dark_grey);
      text-align: center;
    }

    .msg-desc {
      margin: 0;
      color: var(--c_dark_grey);
      text-align: center;

      .first-el {
        font-weight: 400;
      }

      .second-el {
        margin-top: 14px;
      }
    }
  }

  /* When collapsed */
  &.collapsed {
    height: 40px;

    .action {
      .show {
        display: block;
      }

      .hide {
        display: none;
      }
    }
  }
}

.card-blur {
  position: relative;
  display: flex;
  flex: 0 1 393px;
  flex-direction: column;
  justify-content: center;
  height: 164px;
  overflow: hidden;
  border: 1px solid var(--c_grey_100);
  background-image: url('../../../images/icons/card-blur-refact.png');
  background-position: center center;
  text-align: center;

  &.hidden {
    display: none;
  }

  &.first-hotel,
  &.second-hotel {
    margin-right: 31px;
  }

  &.not-background {
    background-image: none;

    .s {
      color: var(--c_grey_200);
    }
  }

  .card-msg {
    padding: 16px;
    margin: 0;
  }
}

body.has-sticky-footer {
  #footer-sticky {
    display: block;
  }
}

//
// Mobile
//
@include media($mobile) {
  body.has-sticky-footer {
    #footer-sticky {
      display: none;
    }
  }
}

@include tabletStyles {
  #footer-sticky {
    height: 395px;

    .card-blur {
      &.first-hotel,
      &.second-hotel {
        margin-right: 0;
      }
    }

    .content {
      .cards {
        .comp-card-footer {
          height: 235px;

          &:first-child,
          &:nth-child(2) {
            margin-right: 0;
          }
        }
      }

      .actions {
        flex-direction: column;
      }
    }
  }
}
