@use 'internal_nav_commons' as *;

.internal-nav-cnt-nfh {
  display: none;
}

.internal-nav-cnt-nfh {
  overflow-y: scroll;

  &.nav-up {
    max-height: calc(100vh - 71px);
  }

  &.nav-down {
    max-height: calc(100vh - 136px);
  }

  &.wrapper {
    padding: 0;
  }

  ul {
    position: relative;
    transform: none !important;
    display: flex;
    flex-direction: column;

    .multi-button {
      padding: 0;

      .btn-primary {
        width: 100%;
        height: 50px;
        line-height: 50px;
      }
    }
  }

  &:not(.centered) {
    ul {
      .multi-button {
        padding: 0;
      }

      li {
        flex: 0 0 auto;
        width: auto;
        padding: 12px 20px;

        &.active,
        &.show {
          display: block;
        }

        &::after {
          display: none;
        }

        a {
          padding: 2px 5px 4px;
        }
      }
    }
  }
}

.internal-nav-cnt-nfh--in-bar {
  display: block;
  width: 100%;

  .internal-nav {
    background: none;

    .internal-nav-list {
      flex-direction: row;
      margin-bottom: 12px;

      .menu_item {
        transform: translateX(0);
        padding: 0;

        &.active {
          .menu_item__text {
            color: var(--c_primary);
            font-weight: var(--sans-bold);
          }
        }

        .menu_item__link {
          padding: 12px 8px 0;
          white-space: nowrap;

          &::after {
            top: 0;
            bottom: auto;
            background-color: var(--c_primary);
          }
        }
      }
    }
  }
}

// PY04000-14
.internal-nav.with-dropdown {
  padding: 0;

  .menu {
    justify-content: center;
    overflow: auto hidden;
  }

  .has-children {
    position: static;
    white-space: nowrap;

    .wrapper {
      position: absolute;
      width: auto;
      max-width: none;
      padding: 0;
      margin: 0;
    }

    .drop-down-links {
      left: auto;
      right: -38px;
    }

    .drop-down-item {
      a {
        width: 100%;
      }
    }
  }
}

// PY05355-21
.hotel-explore {
  &__list {
    &-item {
      @include icon(arrow-right-fat, after) {
        padding: 0;
        border-radius: 0;
      }
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      border-bottom: 1px solid var(--c_grey_200);
    }
  }

  &__link {
    width: 100%;

    &-text {
      @include fontSansSemiBold;
      @include fontSize(16);
      color: var(--c_dark_grey);
    }
  }
}
