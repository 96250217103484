@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.segmented-messages-banner {
  padding: 24px 0;
  background-color: var(--c_grey_100);

  .block {
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 10%);
    background: var(--c_white);
  }

  .newsletter-left-content {
    width: 100%;
  }

  .newsletter-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 76px;
    background-color: var(--c_dark_blue_horizons);

    .image {
      width: 139px;
      height: 37px;
    }
  }

  .newsletter-content {
    display: flex;
    justify-content: space-between;
  }

  .newsletter-info {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    order: 1;
    padding: 40px;
  }

  .newsletter-title {
    @include fontSerifBold;
    @include fontSize(26);
    width: 100%;
    margin-bottom: 20px;
    color: var(--c_dark_blue_horizons);
    line-height: 36.58px;
  }

  .newsletter-text {
    width: 100%;
    line-height: 26px;

    > p,
    li {
      @include icon(check, before, 's') {
        margin-right: 17px;
        color: var(--c_green);
        line-height: 24.51px;
      }

      @include fontSize(18);
      display: flex;
      margin-bottom: 20px;
      line-height: 24.51px;

      &:last-child {
        margin-bottom: 16px;
      }
    }

    li {
      line-height: 26px;
    }
  }

  .newsletter-link {
    @include link-base;
    @include fontSize(16);
    margin-left: 31px;
    line-height: 21.79px;
  }

  .newsletter-image {
    position: relative;
    order: 2;
    min-height: 100%;
    overflow: hidden;

    picture {
      height: 100%;
    }

    img {
      display: block;
      height: 100%;
    }
  }

  .my-iberostar-page & {
    margin-bottom: 24px;

    .wrapper {
      padding: 0;
    }
  }
}
