@use 'cards_rooms_commons' as *;

.card.room {
  display: flex;
  flex-direction: column;

  .card-image {
    min-height: 135px;
  }

  .text {
    margin-bottom: 20px;
  }

  .t-highlight {
    margin-bottom: 8px;
  }

  .bottom-list {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    padding-right: 0;
    margin-bottom: 10px;
    overflow: visible;

    .feature {
      display: none;

      &:first-child,
      &:nth-child(2) {
        display: block;
        flex: 0 0 auto;
        margin-bottom: 4px;
        line-height: 24px;
      }
    }

    .link-circle {
      display: none;
    }
  }

  .hint-cnt {
    margin-bottom: 10px;
  }

  .highlight {
    .t-price {
      // TO DO: check with Maria Molinero if sketch is correct
      @include fontSize(30);
      line-height: 41px;
    }
  }

  .l-down {
    margin-top: 10px;
  }

  .claim {
    @include fontSansSemiBold;
    @include fontSize(16);
    margin-bottom: 10px;
    color: var(--c_dark_grey);
    font-weight: 600;
  }

  .info-links {
    justify-content: space-evenly;
    margin-top: 10px;
  }
}

.popup-room {
  .rooms-services-list {
    padding: 16px 24px;

    li {
      width: 50%;
      padding: 8px 0;
    }
  }
}
