@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.destination-index-container {
  .filtering-container {
    .destination-header-collapsable {
      border-top: 1px solid var(--c_primary);
      padding: 23px 16px;

      &__summary {
        justify-content: space-between;
        cursor: pointer;

        &-header {
          &--title {
            @include fontSize(32);
          }

          &--counter {
            font-weight: $sans-regular;
            margin-left: 25px;
            line-height: 65px;
            color: var(--c_masterbrand_blue_700);
          }
        }

        &-button {
          font-size: 20px;
          display: flex;
          align-items: center;
          color: var(--c_turquoise);
            &.active {
              transform: rotate(180deg);
            }
        }
      }
    }

    &:last-child {
      border-bottom: 1px solid var(--c_primary);
    }
  }

  .destination-list {
    margin-top: 20px;

    &__container {
      padding: 20px;
      background-color: var(--c_blue_green_100);
      border-radius: 20px;
      margin-bottom: 20px;
    }

    &__header {
      display: flex;
      margin-bottom: 15px;

      &--title {
        color: var(--c_blue_700);
        @include fontSize(20);
        font-weight: 700;
      }

      &--link {
        @include fontSize(16);
        line-height: 25px;
        margin-left: 20px;
        text-decoration: underline;
      }
    }

    &__items {
      .destination-card {
        display: flex;
        height: 96px;
        width: 320px;
        background-color: var(--c_masterbrand_white);
        border: 1px solid var(--c_grey_300);
        border-radius: 10px;
        overflow: hidden;

        &__image {
          flex-basis: 35%;
          flex-grow: 1;

          picture {
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
            // Para prevenir el position absolute que vienen por defecto y posiciona el contenedor encima de todo
            &::after {
              position:relative;
            }
          }
        }

        &__content {
          flex-basis: 65%;
          padding: 20px;
          color: var(--c_blue_700);

          &--title {
            @include fontSize(20);
            font-weight: 400;
          }

        }
      }
    }
  }
}
