@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.information-modules {
  > .wrapper {
    overflow: hidden;

    &.block-title-header {
      overflow: visible;

      &.text-centered {
        flex-direction: column;
        text-align: center;

        & > div {
          width: 100%;
        }
      }

      &.text-right {
        flex-direction: row-reverse;
        text-align: right;
      }
    }

    & .info-items {
      .info-item {
        .image:has(.video-content) {
          @include icon(play-circle, after, 'xxl') {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%);
            cursor: pointer;
            color: var(--c_white);
            pointer-events: all;
          }
          position: relative;
          pointer-events: none;

          .img-poster {
            img {
              width: 100%;
              object-fit: cover;
              aspect-ratio: 3 / 2;
            }
          }
        }
      }
    }
  }
}

.modal.is-open {
  .carousel-videos {
    width: 100%;
    max-width: 1200px;

    .modal__content {
      position: relative;
    }

    .swiper-carousel-video {
      position: relative;
      overflow: hidden;

      .swiper-slide {
        width: 100%;

        .video-content {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center center;
          aspect-ratio: 16/9;
        }
      }
    }

  }
}
