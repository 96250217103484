@use 'affiliate_tabs_commons' as *;
@use '../../shared/internal_nav/internal_nav_mobile';

.affiliate-tabs {
  .internal-nav-cnt {
    padding: 0;
    overflow: auto;
    overflow-x: auto;
    scrollbar-width:thin;
  }

  .js-tabs-content {
    .js-tab {
      padding-top: 20px;

      > ul {
        > li {
          flex: 0 0 100%;
          width: 100%;
          padding: 10px 0;
        }
      }
    }
  }
}
