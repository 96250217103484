@use './hotel_collapsible_commons' as *;

.hotel-collapsible {
  padding: 20px 0;

  &__summary{
    &-header{
      gap: 10px
    }

    &-title{
      @include fontSize(16);
      line-height: 20px;
    }

    &-description{
      @include fontSize(16);
      line-height: 20px;
    }

    &-button{
      width: 30px;
      height: 30px;
    }
  }

  &__content-header{
    margin-bottom: 25px;

    .hotel-collapsible__content-title{
      @include fontSize(30);
      line-height: 35px;
      text-align: left;
    }

    .hotel-collapsible__content-description{
      @include fontSize(16);
      line-height: 20px;
    }
  }
}
