@use 'information_module_item_commons' as *;
@use '../../../shared/info_item/info_item_mobile';
@use '../../../shared/elements/embedded_video/embedded_video_mobile';

.information-modules {
  .info-items {
    padding: 0;

    .gallery-wrapper {
      max-height: 194px;

      .swiper-pagination {
        position: absolute;
        top: 135px;
        left: 47%;
        transform: translateX(-50%);
        width: fit-content;
      }

      .swiper-button-prev {
        position: absolute;
        top: 40%;
        left: 3%;
      }

      .swiper-button-next {
        position: absolute;
        top: 40%;
        left: 82%;
      }
    }
  }

  .link-cnt {
    cursor: pointer;
  }

}
