@use 'block_title_header_commons' as *;

.block-title-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  > * {
    flex: 0 0 100%;
    align-self: flex-end;
  }

  a {
    display: inline-block;
    margin-top: 10px;
  }
}
