@use 'special_landing_highlight_commons' as *;

.special-landing-highlight {
  &__title {
    @include fontSize(24);

    &--detail {
      @include icon(iberostar-logo, before, 's');
    }
  }
}

.vertical-animation {
  &__item {
    @include fontSize(24);
  }
}
