@use 'hotels_filter_map_commons' as *;

#hotels-filter-map {
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  float: none;
  width: 100%;
  height: 100%;
}
