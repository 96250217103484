@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';
@use '../../elements/shared/shared';

.b-last-searches-cards {
  padding: 24px 20px;
  text-align: left;

  .flex-container {
    display: flex;

    .last-search-card {
      @include shadow-blurred(var(--c_white));
      display: flex;
      overflow: hidden;

      .full-link {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: transparent;

        &:focus {
          & + .image {
            & + .content {
              .action {
                > span {
                  &::before {
                    transform: translateY(4px);
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }

      .action {
        .last-search-view-search {
          position: relative;
          color: var(--c_primary);
          text-decoration: none;

          &::before {
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translateY(9px);
            content: '';
            opacity: 0;
            width: 100%;
            height: 3px;
            background: var(--c_blue_green_100);
            transition: transform 0.3s, opacity 0.3s;
            pointer-events: none;
          }
        }
      }

      &:hover {
        .action {
          .last-search-view-search {
            &::before {
              transform: translateY(4px);
              opacity: 1;
            }
          }
        }
      }

      .image {
        background-position: 50% 50%;
        background-size: cover;
      }

      .content {
        display: flex;
        flex: 0 1 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 1px solid rgba(227, 233, 245, 50%);
        border-left: 0;
        background-color: var(--c_white);
        color: var(--c_dark_grey);

        .badges-list {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 4px;

          .badge-element {
            margin-right: 5px;

            .badge {
              @include fontSize(11);
              padding: 4px;
            }
          }
        }
      }

      .t-highlight {
        padding-bottom: 5px;
        text-align: left;
      }

      .action {
        display: flex;
        justify-content: space-between;

        > * {
          flex: 0 0 auto;
          align-self: center;
        }
      }

      .shared {
        position: relative;
        z-index: 2;
        width: 32px;
        max-width: 32px;
        height: 32px;
        overflow: hidden;
        font-size: 0;
        line-height: 17px;
        transition: max-width 0.4s ease-in-out, padding 0.4s ease-in-out;

        &.show-on-mobile-menu {
          display: none;
        }

        &:hover,
        &:focus {
          @include fontSize(12);
          width: auto;
          max-width: 100%;
          padding: 7px 36px 8px 12px;
        }

        &::after {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
}

.b-last-searches-cards-home {
  display: none;
  padding: 20px 0 19px;
  background: var(--c_cloud);
  color: var(--c_grey_600);

  &.show {
    display: block;
  }

  &.ibercuba {
    padding-top: 110px;
  }

  + .masonry-modules {
    margin-top: 30px;
  }

  .last-search-card {
    cursor: pointer;
  }

  .b-title {
    margin: 0 0 10px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1.52px;
    text-transform: uppercase;
  }

  .flex-container {
    display: flex;
    justify-content: flex-start;

    &.half {
      .last-search-card {
        flex: 0 0 calc(50% - 20px);
        width: calc(50% - 20px);
      }
    }
  }

  &.b-last-searches-cards {
    .text-segment {
      display: none;
    }

    .t-highlight {
      font-size: 1rem;
    }

    .dates {
      font-weight: 400;
    }

    .flex-container {
      .last-search-card {
        .image {
          display: none;
        }
      }
    }

    .b-title {
      font-size: 1.5rem;
      font-family: var(--font-serif);
      line-height: 2rem;
      letter-spacing: initial;
      text-transform: none;
    }

    .rating-stars {
      padding-left: 1rem;
    }
  }
}

@include commonTabletMobile {
  .b-last-searches-cards {
    padding: 20px;
    background-color: #EFEFF8;

    span {
      display: inline-block;
      margin: 0 0 10px;
    }

    .flex-container {
      flex-wrap: wrap;

      .last-search-card {
        display: flex;
        flex: 0 0 100%;
        width: 100%;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }

        .image {
          display: none;
        }

        .content {
          padding: 10px;
        }

        .dates {
          padding-bottom: 8px;
        }
      }
    }
  }

  .b-last-searches-cards-home {
    padding-bottom: 0;
    background-color: var(--c_cloud);

    .flex-container {
      .last-search-card {
        flex: 0 0 calc(50% - 10px);
        margin-top: 0;
        margin-bottom: 19px;

        .image {
          display: block;
          flex: 0 0 108px;
          -webkit-box-flex: 0;
        }
      }

      &.half {
        flex: 0 0 calc(50% - 10px);
      }
    }
  }
}

@keyframes check {
  0% {
    width: 0;
    height: 0;
  }

  25% {
    width: 10px;
    height: 0;
  }

  50% {
    width: 10px;
    height: 20px;
  }
}
