@use 'share_offer_commons' as *;

.share-offer {
  background: transparent;

  .action {
    padding: 16px 0;
    text-align: right;
  }

  .shared {
    padding: 10px 0 12px 24px;
    border-radius: 22px;
    font-size: 14px;

    &::after {
      width: 42px;
      height: 42px;
      margin-left: 21px;
      line-height: 40px;
    }
  }

  &.fixed {
    position: fixed;
    left: 0;
    bottom: 50px;
    right: 0;
    z-index: 3;
  }
}
