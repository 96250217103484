/* stylelint-disable */
@use 'recommended_insp_guide_articles.scss';

.recommended-articles-insp-guide {
  padding: 15px;
  margin-top: 30px;

  &::after {
    content: unset;
  }

  .header-image{
    top: 15px;
  }

  .title-container {
    font: 700 26px/36px var(--font-serif);

    .title {
      font: 700 26px/30px var(--font-serif);
      padding: 0;
    }
  }

  > .wrapper {
    gap: 15px;
    padding: 40px 15px 15px;
  }

  .article-wrapper.swiper-wrapper {
    flex-flow: column;
    margin-top: 15px;

    .article {
      flex: 1 0 100%;
      display: flex;
      flex-flow: row;

      .main-image.image-link {
        height: 110px;
        width: calc(100% / 3);
      }

      .main-text {
        width: calc((100% / 3)*2);
        max-height: 110px;

        .article-title {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .article-wrapper-footer {
    padding: 10px 0;
    .s-link {
      font: 700 16px var(--font-sans);
    }
  }
}
