@use 'filter_tabs_commons' as *;

.e-filter-tabs {
  .content-tab {
    font: var(--sans-semi-bold) 10px/14px var(--font-sans);

    .s {
      font-size: 16px;
    }
  }
}
