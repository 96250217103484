@use 'masonry_modules_commons' as *;
@use './destination_masonry_link/destination_masonry_link_mobile';

.masonry-modules {
  @include swiperPaginationCustom;
  padding: 0 20px 14px;

  &::after {
    height: 55.7%;
  }

  .wrapper {
    padding: 0;
  }

  .block {
    flex: 0 0 250px;

    .view-more {
      padding-top: 15px;
    }

    .title {
      padding-bottom: 15px;
      line-height: 1.2em;
    }

    .t-label {
      margin-bottom: 14px;
    }

    .content {
      padding: 0 20px;
    }
  }
}

.ada {
  .masonry-modules .block img {
    filter: brightness(0.55);
  }
}
