@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.special-landing-highlight {
  padding: $space5 0;
  background: var(--c_grey_100);

  &--centred {
    text-align: center;
  }

  &__title {
    line-height: normal;

    &--detail {
      @include icon(iberostar-logo, before, 's') {
        position: relative;
        top: -20px;
      }
    }
  }
}

.vertical-animation {
  color: var(--c_primary);

  // Solution for iOs animation
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;

    // flex-direction: column-reverse;
    // height: 0;
  }

  &__item {
    position: absolute;
    margin: 5px 0;
    font-weight: 600;
    line-height: normal;
  }

  .to-top {
    transform: translateY(-100px);
  }

  .to-visible {
    transform: translateY(0);
  }

  .to-bottom {
    transform: translateY(100px);
  }

  // Animation to the top position
  .to-visible.to-top {
    transform: translateY(-100px);
    transition: all ease-in-out 0.7s;
  }

  // Animation to the visible position
  .to-bottom.to-visible {
    transform: translateY(0);
    transition: all ease-in-out 0.7s;
  }
}
