@use 'call_center_commons' as *;
@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.block-call-center {
  padding: 0 20px;

  &-wrapper {
    padding: 0;
  }

  .call-center{
    $self: &;
    flex-direction: column;

    &__chat{
      flex-direction: column;
      width: 100%;

      &-image{
        width: 100%;
        height: 100%;

        &-circle{
          aspect-ratio: 16/9;
        }
      }

      &-bubbles{
        gap: 15px;
        padding: 20px;

        &-bubble{
          padding: 15px;
        }
      }
    }

    &__title{
      gap: 15px;
      width: 100%;
      padding: 0 20px 20px;

      &-text{
        @include fontSize(16);
        line-height: 24px;
      }

      &-button{
        width: 100%;
      }
    }

    &__subtitle{
      @include fontSize(16);
      line-height: 24px;
    }
  }
}
