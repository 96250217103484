@use 'horizontal_card_commons' as *;

.horizontal-card {
  &.offer {
    .price,
    .coin {
      @include fontSize(16);
    }
  }

  .card-container {
    padding: 15px 10px;

    .text-header {
      margin-bottom: 7px;
    }

    .location {
      margin-bottom: 5px;
    }

    .t-highlight {
      @include fontSize(16);
      line-height: 26px;
    }

    .stars {
      display: inline-block;
    }

    .opinions {
      margin-bottom: 10px;

      .xsmall {
        @include fontSize(12);
        line-height: 14px;
      }
    }

    .badges-list {
      margin-bottom: 10px;
    }

    .holidaycheck-widget {
      margin-bottom: 10px;
    }
  }

  &.card.hotel {
    .call-to-action {
      padding: 0;
    }
  }

  .info-links {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #F0F0F5;
  }

  .price-cnt {
    display: flex;
    justify-content: flex-end;

    .s {
      height: 16px;
      margin-left: 5px;

      &::after {
        display: block;
      }
    }

    .basic-tooltip {
      left: auto;
      right: -30px;
      transform: translateX(0);

      &::before {
        left: auto;
        right: 29px;
        transform: translateX(0);
      }

      &::after {
        left: auto;
        right: 28px;
        transform: translateX(0);
      }
    }
  }

  .prev-type,
  .prev-highlight,
  .prev-highlight .price,
  .prev-highlight .coin {
    @include fontSansSemiBold;
    @include fontSize(12);
    color: var(--c_carrot);
    line-height: 18px;
  }

  .prev-highlight {
    position: relative;
    margin-left: 5px;

    .price,
    .coin {
      @include fontSize(12);
    }

    &::after {
      position: absolute;
      top: 8px;
      left: 0;
      content: '';
      width: 100%;
      height: 1px;
      background: var(--c_carrot);
    }
  }

  .type {
    margin: 2px 5px 0;
    font-weight: 600;
  }

  .hint-cnt {
    @include fontSansSemiBold;
    @include fontSize(12);
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;
    line-height: 18px;
    text-align: right;

    .s::after {
      color: var(--c_primary) !important;
      font-size: 14px !important;
    }
  }

  .btn-primary {
    width: 100%;
  }

  &.comparator-card {
    .card-container {
      display: block;
    }
  }

  &.card.hotel {
    .call-to-action {
      padding: 20px 0 0;
    }

    .text {
      .opinion-widget {
        position: absolute;
        transform: translateY(50px);
      }
    }

    .secondary-btn {
      margin: 0 auto;
    }
  }
}

@media only screen and (width <= 320px) {
  .horizontal-card.card.hotel.holidaycheck-card .call-to-action {
    margin-top: 150px;
  }
}
