@use 'timing_bar_commons' as *;

.timing-bar {
  background: var(--c_turquoise_secondary);

  &.flex-start {
    justify-content: flex-start;
  }

  .timing-content {
    color: #000;

    .value,
    .text {
      @include fontSize(20);
    }
  }
}
