@use 'footer_commons' as *;
@use '../../shared/social_list/social_list_mobile';
@use '../../elements/badge/badge_mobile';
@use '../../shared/footer_newsletter/footer_newsletter_mobile';

body {
  &:not(.inspiration-guide) {
    .footer-contact {
      .wrapper {
        padding: 0;
      }

      .flex {
        flex-wrap: wrap;

        .contact-us-btn {
          flex-wrap: wrap;

          li {
            width: 100%;
          }

          .footer-contact-btn {
            width: 100%;
            border: 1px solid var(--c_primary);
            background: transparent;
            color: var(--c_primary);

            &.mobile-only {
              display: flex;
            }
          }

          .footer-contact-form,
          .footer-need-help {
            justify-content: baseline;
            padding: 7px 0 16px;
            margin-left: 0;
            border: none;
            color: var(--c_primary);
          }
        }

        .footer-contact-content {
          &.title {
            border-bottom: 1px solid var(--c_grey_200);
          }

          &.opened-container {
            .title {
              border-bottom-style: none;
            }

            .social-links {
              padding-bottom: 17px;
              margin-top: 0;
              border-bottom: 1px solid var(--c_grey_200);

              > ul {
                > li {
                  width: 20%;
                  margin: 10px 0;

                  &:nth-child(4),
                  &:nth-child(8) {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }

      .contact-us-btn {
        .footer-contact-form {
          justify-content: flex-start;

          &::before {
            display: none;
          }
        }

        .footer-need-help {
          justify-content: flex-start;

          &::before {
            display: none;
          }
        }

        .show-on-mobile {
          display: block;
        }

        .hide-on-mobile {
          display: none;
        }
      }

      .t-label {
        &.mb-4 {
          margin-bottom: 15px;
        }
      }

      .footer-selectors {
        display: inline-flex;
        width: 100%;
        margin: 20px 0;
        border-top: 1px solid rgba(255, 255, 255, 10%);

        .language-selector,
        .currency-selector {
          border-bottom: 1px solid rgba(255, 255, 255, 10%);

          .t-label {
            padding-bottom: 10px;
          }

          .fk-select::after {
            margin-left: 8px;
            font-weight: var(--sans-bold);
          }
        }

        .language-selector {
          margin-right: 16px;
        }

        .currency-selector {
          .fk-select {
            padding-left: 16px;
            border-left: 1px solid var(--c_grey_200);
          }
        }
      }

      .app-stores {
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, 10%);

        .title {
          @include fontSize(12);
          color: var(--c_white);
          line-height: 18px;
          letter-spacing: 1.5px;
          text-transform: uppercase;
        }

        > li:not(.title) {
          margin-top: 20px;
        }
      }

      .footer-contact-left,
      .xsmall {
        display: none;
      }

      .footer-contact-right,
      .legal-information-container,
      .footer-contact-content {
        padding: 0 20px;

        .title {
          border-bottom: 1px solid var(--c_grey_200);
        }
      }

      .footer-bottom-container {
        .xsmall {
          padding: 20px 0;
          border-bottom: 1px solid var(--c_grey_200);
        }
      }

      .legal-information-container {
        &.opened-container {
          .title {
            border-bottom-style: none;
          }
        }

        .footer-bottom-mobile {
          letter-spacing: initial;
          text-transform: none;

          &.opened {
            padding-bottom: 0;
          }
        }
      }
    }
 // this code is very important for the ibercuba footer please before modified it see the webpage it's working prperly. Don't remove it
    .footer-nav {
      .experiences-nav.ibercuba {
        position: relative !important;
        display: flex !important;
        flex-direction: column !important;
        padding: 0 !important;
        margin: 0 20px;
        border-bottom: 1px solid var(--c_grey_200);

        .custom-css-class {
          .menu_item {
            padding: 10px 0!important;
            margin-top: 0 !important;
          }

          .menu_item:last-child{
            margin-top: 8px;
            border-bottom: none !important;
          }

          .menu_item:first-child{
            margin-top: 8px !important;
            border-bottom: none !important;
          }
        }
      }

      .experiences-nav .menu_item.experiences.title:first-child {
        display: none;
      }

      .manage-bookings-nav-container.ibercuba {
        margin: 20px !important;
      }

      .manage-bookings.title.menu_item {
        display: flex;
        width: 100%;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--c_grey_200);
        color:var(--c_dark_grey) !important;

        ::after {
          content: none;
          display: none;
        }
      }

      .hotels-nav-container {
        padding: 0 20px;

        .menu_item {
          display: none;
        }

        .menu_item {
          display: block;
          padding: 10px 0!important;
        }

        .hotels-link {
          padding-top: 0;
        }

        > div {
          > ul {
            &:last-child {
              padding-bottom: 10px;
            }
          }
        }

        .hotels-footer-panel {
          border-bottom: 1px solid var(--c_grey_200);
        }

      }

      .wrapper {
        padding: 0;

        > div,
        > li {
          flex: 0 0 100%;

          &.opened-container-mobile {
            li {
              padding: 10px 0;

              a:not(.section-title) {
                color: var(--c_primary);
              }

              .section-title {
                padding-bottom: 13px;
              }

              &.title a,
              &.hotels-link a {
                padding-bottom: 0;
                color: var(--c_dark_grey);
              }

              &.title {
                padding: 0;
              }

              &:first-child {
                border-bottom: none !important;
              }

              &:last-child {
                padding-bottom: 20px;
              }
            }

            .hotels-nav-content {
              .hotels-link {
                padding-top: 0;
              }

              > div {
                > ul {
                  &:last-child {
                    padding-bottom: 10px;
                  }
                }
              }
            }
          }

          > ul {
            padding: 0 20px;

            &:not(.mobile-only) {
              display: flex;
              flex-direction: column;

              > li {
                flex: 0 0 100%;

                &:first-child {
                  border-bottom: 1px solid var(--c_grey_200);
                }

                &.title,
                &.hotels-link {
                  flex: 0 0 100%;

                  &::after {
                    display: none;
                  }
                }

                &:last-child {
                  &::after {
                    display: none;
                  }
                }
              }
            }

            > li {
              > ul {
                display: flex;
                flex-wrap: wrap;

                > li {
                  flex: 0 0 auto;

                  &::after {
                    content: '-';
                    margin: 0 10px;
                    color: var(--c_white);
                  }

                  &.title,
                  &.hotels-link {
                    flex: 0 0 100%;
                    border-bottom: 1px solid rgba(255, 255, 255, 10%);

                    &::after {
                      display: none;
                    }
                  }

                  &:last-child {
                    &::after {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer-bottom {
      padding-top: 20px;

      .flex {
        flex-wrap: wrap;

        > li {
          padding-bottom: 15px;
          padding-right: 10px;

          a {
            @include fontSize(12);
            padding-right: 10px;
            border-right: 1px solid var(--c_grey_200);
            color: var(--c_primary);
            line-height: 16px;
          }

          &:nth-last-child(2) {
            a {
              border: none;
            }
          }

          &:last-child {
            a {
              border: none;
              font-weight: normal;
            }
          }
        }
      }
    }

    .footer-my-iberostar {
      ul {
        flex-wrap: wrap;
        text-align: left;

        li {
          flex-basis: 100%;
          padding: 20px 0 0;

          &:first-child {
            padding: 20px 0 0;
          }

          &:last-child {
            padding-bottom: 20px;
          }
        }
      }
    }

    .main-footer {
      .title,
      .hotels-link {
        a {
          @include icon(arrow-down) {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            font-weight: var(--sans-bold);
            font-size: 12px;
          }
          position: relative;
          display: block;
          padding: 20px 20px 20px 0;
          color: var(--c_dark_grey);

          &.opened {
            @include icon(arrow-up) {
              padding-top: 20px;
              margin-top: 0;
              font-weight: var(--sans-bold);
            }
          }

          &.footer-contact-us-mobile {
            &.opened {
              @include icon(arrow-up) {
                padding-top: 0;
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    #msg-currency-change {
      width: calc(100% - #{15px * 2});
      margin: 15px;

      .currency-title {
        @include fontSize(18);

        // Se le resta el width + margin del close button
        width: calc(100% - 16px - 17px);
        padding: 26px 21px 15px;
      }

      .fancybox-close-small {
        width: 16px;
        height: 22px;
        margin: 18px 17px 0 0;
      }

      .currency-content {
        padding: 21px;
      }

      .currency-footer {
        padding: 0 21px 24px;

        .action-btn:first-of-type {
          margin-right: 8px;
        }
      }
    }
  }
}
