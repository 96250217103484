@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.modal-module-nfh {
  .hotel-info-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 28px;

    .title-wrapper {
      display: flex;
      margin-bottom: 8px;

      .title {
        @include fontSize(24);
        @include fontSerifSemiBold;
        padding-right: 8px;
        color: var(--c_dark_grey);
      }

      .stars {
        .rating-stars {
          @include fontSize(14);

          &::before {
            color: var(--c_dark_grey);
          }
        }
      }
    }
  }

  .tabs-wrapper {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border: 1px solid var(--c_grey_500);
    }

    li {
      position: relative;
      line-height: 20px;
      text-align: center;

      a {
        cursor: pointer;
        display: inline-block;
        padding: 7px 15px;
        color: var(--interactive-text-base);
        font-weight: var(--f_font_weight_700);

        &:hover {
          text-decoration: none;
        }
      }

      &:first-child {
        border-left: 0;
      }
    }
  }

  .content-tab {
    padding: 24px 120px 40px;
    background-color: #f8f8f8;

    .wrapper-content {
      display: flex;
      padding: 40px 24px;
      background-color: var(--c_white);

      figure {
        position: relative;
        flex: 0 0 auto;
        width: 320px;
        height: 320px;
        margin: 0;
        margin-left: -64px;
        overflow: hidden;

        img {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        video {
          width: 100%;
          height: 100%;
          max-height: 320px;
          object-fit: cover;
        }
      }

      .wrapper-text {
        flex: 0 1 auto;
        padding-left: 24px;

        .title {
          @include fontSize(18);
          margin-bottom: 8px;
          color: var(--c_dark_grey);
          font-weight: var(--sans-semi-bold);
          line-height: 1.44;
        }

        p {
          margin-bottom: 10px;
        }

        a {
          @include link-styles;
        }
      }
    }
  }
}
