@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

@include commonStyles {
  .video-module {
    @include image_filter;
    position: relative;
    transform: scale(1) translate3d(0, 0, 0);
    overflow: hidden;
    background-color: var(--c_primary);
    transition: all 0.3s;

    .cover {
      position: relative;
      display: block;
      width: 100%;
      max-width: 1240px;
      height: 0;
      padding-top: 25px;
      padding-bottom: 40.8%; /* 21:9 */

      .video-link {
        @include icon(play-circle, before, 's') {
          margin-bottom: 20px;
          font-size: 70px;
        }
        position: absolute;
        top: calc(50% - 8px);
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        color: var(--c_white);
        font-weight: 700;

        .text {
          @include fontSize(14);
          @include fontSansBold;
          opacity: 0;
        }
      }

      picture{
        position: unset;
      }

      img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
