@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.background-information-horizontal-module {
  width: 100%;
  padding: 40px 0 0;

  .wrapper {
    padding: 20px 20px 0;
    background: var(--background-color-defaul);
  }

  .information-horizontal-module-pagination {
    display: none;
  }
}

.information-horizontal-module {
  .information-horizontal-module-li {
    padding-bottom: 40px;

    &:nth-child(even) {
      .horizontal-card {
        &.info {
          &.information-horizontal-module-item {
            .card-image {
              order: 1;
              margin-left: 0;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

.information-horizontal-module-item {
  .pretitle {
    & + .block-title {
      margin-top: 10px;
    }
  }
}

.horizontal-card {
  &.info {
    margin: 10px auto;
    border: 0;

    &.information-horizontal-module {
      margin: 0 auto;

      .card-image {
        @include image_filter;
        position: relative;
      }
    }

    &:hover {
      box-shadow: 0 0 0 transparent;
    }

    .card-image {
      position: static;
      float: left;
      width: calc(32.66667% - 5px);
      height: auto;

      img {
        position: relative;
        top: 0;
        transform: none;
        height: auto;
      }
    }

    .card-container {
      display: block;
      width: calc(67.3333% + 5px);
      height: 300px;
      overflow: hidden;
      background: var(--c_background);

      &.untruncated {
        height: auto;
        padding-bottom: 60px;

        .l-down {
          padding: 20px;

          .close {
            display: inline;
          }

          .more {
            display: none;
          }
        }
      }
    }

    .title {
      color: var(--c_blue_700);
    }

    .desc {
      margin: 0;
      color: var(--color-body-font);

      p {
        margin: 1em 0;
      }

      a {
        font-size: inherit;
      }

      ul {
        li {
          position: relative;
          padding-left: 16px;
          margin: 1em 0;

          &::before {
            position: absolute;
            top: 50%;
            left: 0;
            content: '';
            width: 7px;
            height: 7px;
            margin-top: -4px;
            background: var(--c_primary);
          }
        }
      }
    }

    .l-down {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 20px;
      text-decoration: none;

      &.hidden {
        display: none;
      }

      .close {
        display: none;
      }
    }

    &.information-horizontal-module-item {
      display: flex;
      align-items: stretch;
      margin: 0 auto;
      border: 0;

      &:hover {
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 28%);
      }

      .card-image {
        @include image_filter;
        position: relative;
        flex: 0 0 390px;
        align-self: center;
        order: 0;
        float: none;
        height: 370px;
        max-height: 370px;
        margin: 20px 0 20px 20px;
        overflow: hidden;

        picture {
          width: 390px;
          height: 370px;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 100%;
            min-height: 370px;
            object-fit: cover;
          }
        }
      }

      .card-container {
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 40px 0 0;
        margin: 0;
        margin-left: 40px;
        overflow: hidden;
        background: var(--c_white);

        &.scrolled-up {
          .title {
            position: relative;

            &::after {
              position: absolute;
              top: 100%;
              left: 0;
              z-index: 1;
              content: '';
              width: 100%;
              height: 70px;
              background: linear-gradient(to bottom, rgba(255, 255, 255, 100%) 0%, rgba(255, 255, 255, 0%) 100%);
            }
          }
        }
      }

      .desc {
        position: relative;
        width: calc(100% - 10px);
        padding-bottom: 40px;
        padding-right: 75px;
        margin: 0;
        overflow: auto;

        p {
          margin: 0 0 20px;
        }

        ul {
          margin: 0 0 20px;

          li {
            position: relative;
            padding: 0 0 5px;
            margin: 0;

            &::before {
              display: none;
            }
          }
        }
      }

      .action {
        display: none;
      }
    }
  }
}

// Rule for applying a brightness filter to an image
.ada {
  .information_horizontal_module img {
    filter: brightness(0.7);
  }
}
