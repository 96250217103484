@use 'destinations_index_commons' as *;


.destination-index-container {
  .destination-header-collapsable {
    &__summary {
      &-header {
        &--counter {
          line-height: 35px;
        }
      }
    }
  }

  .destination-list {
    &__items {
      .destination-card {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}
