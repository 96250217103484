@use '../../../src/base_css/abstracts/abstracts' as *;
@forward '../../../src/base_css/abstracts/abstracts';

.shared-block {
  &:focus {
    outline: none;
  }

  .desc-card .image .image-bg {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}
