@use 'destination_masonry_link_commons' as *;
.destination-masonry-link {

  &__info {
    margin-top: 10px;
    &--title {
      @include fontSize(20);
    }

    &--subtitle {
      margin-top: 10px;
    }
  }
}
