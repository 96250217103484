@use 'message_hotels_not_found_commons' as *;

.message-hotels-not-found {
  padding-top: 90px;
  margin: 30px auto 40px;

  &::after {
    font-size: 71px;
  }

  .message-title {
    @include fontSize(25);
    margin-bottom: 10px;
  }
}
