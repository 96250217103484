@use 'segmented_messages_banner_commons' as *;

.segmented-messages-banner {
  .newsletter-content {
    flex-direction: column;
  }

  .newsletter-info {
    order: 2;
    width: 100%;
    max-width: unset;
    padding: 30px 22px 25px;
  }

  .newsletter-title {
    @include fontSize(20);
    margin-bottom: 20px;
    line-height: 28px;
  }

  .newsletter-text {
    @include fontSize(18);
    line-height: 25px;

    > p,
    li {
      &:last-child {
        margin-bottom: 20px;
      }
    }

    br {
      content: ' ';
      display: block;
      margin-bottom: 8px;
    }
  }

  .newsletter-image {
    order: 1;

    img {
      width: 100%;
    }
  }
}
