@use 'last_searches_cards_commons' as *;
@use '../../blocks/shared_block/shared_block' ;

@include commonTabletDesktop {
  .shared-block {
    width: 100%;
    max-width: 860px;
    padding: 0;

    .fancybox-close-small {
      @include icon(close-fat, before) {
        display: block;
        font-weight: 600;
        font-size: 16px;
      }
    }

    > div {
      display: flex;
    }

    .form-cnt {
      flex: 0 0 55%;
      padding: 40px;

      .fz-row {
        margin-bottom: 16px;

        .form-text {
          overflow: hidden;
        }
      }

      .action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        text-align: right;

        .come-back-btn {
          .s {
            margin-right: 5px;
          }
        }
      }

      &.w-100 {
        flex: 0 0 100%;
        width: 100%; // ie
      }

      .form-title {
        @include fontSize(24);
        margin-top: 10px;
        font-family: NoeText-Bold;
        line-height: 26px;
      }
    }

    .desc-cnt {
      display: flex;
      flex: 0 0 45%;
      flex-direction: column;
      padding: 40px;
      background: var(--c_background);

      .multimails-container {
        margin-top: 13px;
      }

      .desc-card {
        flex: 0 0 auto;
      }
    }

    .desc-card {
      width: 100%;
      margin-top: 8px;
      overflow: hidden;

      .image {
        width: 100%;
        overflow: hidden;

        .image-bg {
          height: 100%;
          min-height: 189px;
          max-height: 189px;
        }

        img {
          display: block;
          width: 100%;
        }
      }

      .content {
        width: 100%;
        padding: 10px;
        padding: 19px 24px;
        border: 1px solid var(--c_grey_200);
        border-top: none;
        background: var(--c_white);
      }
    }

    &.multiple {
      .form-cnt {
        flex: 0 0 50%;
        padding-top: 40px;
        padding-right: 60px;

        > p {
          margin: 0;
          font-size: 18px;
        }
      }

      .desc-cnt {
        flex: 0 0 50%;
        padding-top: 44px;
      }

      .last-search-card {
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 10px;

        .shared {
          font-size: 14px;
        }
      }
    }
  }

  .multimails-container {
    span {
      position: relative;
      cursor: default;
      display: inline-block;
      width: 98px;
      padding: 3px 20px 3px 3px;
      margin-right: 5px;
      overflow: hidden;
      border: 1px solid var(--c_grey_400);
      border-radius: 4px;
      background: var(--c_white);
      font-size: 12px;
      line-height: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: all 0.25s ease-in-out;
      user-select: none; /* Non-prefixed version, currently */

      a {
        @include icon(close-fat, after, 'xs') {
          transition: color 0.25s ease-in-out;
        }
        position: absolute;
        top: 50%;
        right: 6px;
        transform: translateY(-50%);
      }

      &:hover {
        border-color: var(--c_dark_grey);
        color: var(--c_dark_grey);

        a {
          &::after {
            color: var(--c_dark_grey);
          }
        }
      }
    }
  }

  .b-last-searches-cards-home {
    padding-top: 80px;
    margin-top: -40px;
  }
}

@include desktopStyles {
  .b-last-searches-cards {
    .flex-container {
      .last-search-card {
        flex: 0 0 calc(33.3% - 16px);
        flex-basis: calc(33.3% - 16px);
        width: calc(33.3% - 16px);
        margin-right: 26px;

        &:last-child {
          margin-right: 0;
        }

        .image {
          flex: 0 0 108px;
        }

        .content {
          padding: 10px;

          &--info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;
          }
        }

        .t-highlight {
          padding-bottom: 5px;
        }

        .dates {
          padding-bottom: 10px;
          font-size: 1rem;
        }
      }
    }
  }
}
