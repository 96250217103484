@use 'home_tabs_commons' as *;
@use '../home_tabs_item/home_tabs_item_mobile';
@use '../affiliate_tabs/affiliate_tabs_mobile';
@use '../../elements/filter_tabs/filter_tabs_mobile';
@use '../../elements/badge/badge_mobile';
@use '../../elements/tabs_menu/tabs_menu_mobile';

.home-tabs {
  padding: 20px 0;
  margin: 0 auto;

  .tabs-container__mobile {
    overflow: hidden;
    overflow-x: auto;
    overflow-x: scroll;
    scrollbar-width: none;
  }

  .block-tabs__mobile {
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;

  }

  .block-tabs-name__mobile {
    cursor: pointer;
    flex: 0 0 auto;
    box-sizing: border-box;
    min-width: 150px;
    white-space: nowrap;
  }

  .block-tabs-name-link.active {
    @include interactiveElement;
    position: relative;
  }

  .block-tabs-name-link {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .block-tabs-name {
    text-align: center;
  }

  .b-home-tabs-item {
    display: flex;
    flex-direction: column;

    .block-content {
      order:2;
    }

    a {
      font-weight: 900;
      text-decoration: none;
    }

    .block-image {
      display: inline-block;
      order: 1;
      margin-top: 20px;
    }
  }

  .image {
    position: relative;
    display: flex;
  }

  .block-title {
    margin-bottom: $space2;
  }

  .block-subtitle {
    margin-bottom: 2px;

  }

  .swiper-container {
    padding: 20px 17px;
    overflow: hidden;
    border: 1px solid var(--border-color);
  }

  .block-tabs {
    justify-content: flex-start;

    &.no-swiper {
      display: flex;
      flex: 0 0 100%;
      justify-content: center;
    }

    .block-tabs-name {
      width: auto;

      .block-tabs-name-link {
        padding: 10px 15px;

        .s {
          margin-left: 0;
        }
      }
    }
  }

  &.is-lightbox {
    .e-filter-tabs {
      overflow-x: scroll;
    }

    .wrapper-inner {
      padding: 0 20px;
    }

    .tabs {
      .block-wrapper {
        flex-direction: column-reverse;

        .block-content {
          width: 100%;
          padding: 0;

          .block-title {
            margin-top: $space2;
          }
        }

        .block-image {
          width: 100%;

          img {
            min-height: 190px;
          }
        }
      }
    }
  }
}
