@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.destination-index-tabs-nav {
  li {
    > span {
      position: relative;
      cursor: pointer;
      display: block;
      padding-left: 31px;
      line-height: 26px;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        content: '';
        width: 20px;
        height: 20px;
        border: 1px solid var(--c_dark_grey);
        border-radius: 50%;
      }

      &.active {
        &::before {
          border-color: var(--c_green);
        }

        &::after {
          position: absolute;
          top: 50%;
          left: 3px;
          transform: translateY(-50%);
          content: '';
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: var(--c_green);
        }
      }
    }

    .filters {
      padding-left: 0 !important;
    }
  }
}
