@use 'infographic_modules_commons' as *;
@use '../../elements/scrollsnap_slider/scrollsnap_slider_commons';

.infographic-modules {
  .infographic-submodules {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    width: 100%;

    &.submodules-align-left {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }

    &.submodules-align-right {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  &.with-bg {
    padding-bottom: 19px;

    &::after {
      height: 100px;
    }
  }

  .iberostarpro-home & {
    .swiper-container {
      padding: 0 20px;
    }
  }

  .block-title {
    margin-bottom: 8px;
  }

  &.one-page-hotel {
    .block-title {
      text-align: left;
    }

    .infographic-submodules {
      > .infographic-module-item:not(.with-link) {
        display: block;
        height: auto;
        padding: 16px 20px;
        margin-bottom: 10px;
        border: 1px solid var(--c_grey_200);

        .s {
          display: inline-block;
          margin: 0;
          margin-right: 8px;
          font-size: 32px;
        }

        .title {
          display: inline-block;
        }

        .subtitle {
          margin-top: 8px;
        }
      }
    }
  }

  &.block-4 {
    .infographic-submodules {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid var(--c_grey_200);

      > li:not(.with-link) {
        .mice-home & {
          width: 100%;
        }
      }
    }
  }

  &:not(.block-4) {
    @include bg-half(40%);
    @include swiperPaginationCustom;
  }

  .half & {
    @include bg-half(40%);
    margin: 0;

    &:not(.block-4) {
      &::after {
        display: none;
      }
    }
  }
}

.infographic-submodules {
  &.destination-tips {
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    justify-content: center;

    & > .block {
      justify-content: center;
    }

    & > .block > .s {
      margin: 0 10px 0 0;
    }
  }

  &.ss-slider {
    @include scrollsnap_slider_commons.ss-slider;

    &.submodules-align-right {
      justify-content: end;
    }

    &.submodules-align-left {
      justify-content:flex-start;
    }

    &.infographic-submodule {
      justify-content: center;
    }

    > li:not(.with-link) {
      flex: 0 0 100%;
      max-width: 200px;
    }
  }

  > li:not(.with-link) {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    height: auto;
    min-height: 91px;
    padding: 12px;
    padding-bottom: 16px;
    background: var(--c_white);
    text-align: center;

    a {
      flex: 0 0 100%;

      > * {
        flex: 0 1 auto;
        align-self: center;
        width: 100%;
      }
    }

    &:first-child {
      padding-top: 10px;
    }

    &:last-child {
      padding-bottom: 10px;
    }

    > * {
      flex: 0 1 auto;
      align-self: center;
    }

    .image {
      flex: 0 0 auto;
      margin-bottom: 0;
      margin-right: 10px;

      img {
        width: 24px;
      }
    }

    .subtitle {
      @include fontSize(14);
      flex: 0 0 100%;
      margin-top: 15px;
    }

    .s {
      display: block;
      margin: 0 0 10px;
      font-size: 20px;
    }

    .title {
      display: block;
      width: 100%;
    }
  }

  > li.with-link {
    display: flex;
    flex: 0 0 100%;
    max-width: 200px;
    height: auto;
    padding: 20px;
    border: 1px solid rgba(5, 12, 26, 50%);
    background: var(--c_white);

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .image {
        flex: 0 0 auto;
        margin-bottom: 0;
        margin-right: 10px;

        img {
          width: 32px;

          &.hover {
            display: none;
          }
        }
      }

      .s {
        display: block;
        flex: 0 0 auto;
        margin-bottom: 17px;
        margin-right: 5px;
        font-size: 32px;
      }

      .title {
        flex: 0 1 auto;
        align-self: center;
        color: var(--c_dark_grey);
        text-align: center;
      }

      .subtitle {
        @include fontSize(14);
        flex: 0 0 100%;
        margin-top: 15px;
        text-align: center;
        line-break: 24px;
      }
    }
  }

  > li.with-tooltip {
    cursor: pointer;
  }

  > li {
    width: 100%;

    .description {
      @include fontSize(14);
      margin-top: 8px;
      line-height: 20px;
    }
  }

  .more-info {
    @include fontSansBold;
    @include fontSize(14);
    display: block;
    flex: 0 0 100%;
    margin-top: 8px;
    color: var(--c_primary);
    line-height: 20px;
  }

  .half & {
    flex-direction: column;
    overflow: hidden;

    > li,
    > li:not(.with-link),
    > li.with-link {
      position: relative;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 10px 12px 10px 65px;
      margin: 0 0 12px;
      border: 1px solid var(--c_grey_200);

      .s {
        @include fontSize(30);
        position: absolute;
        top: 18px;
        left: 18px;
      }

      .subtitle {
        @include fontSansRegular;
        margin: 0;
      }

      > * {
        text-align: left;
      }
    }

    > li.with-link a {
      align-items: flex-start;

      .title {
        align-self: flex-start;
      }
    }
  }
}
