@use 'hotel_big_card_commons' as *;

.hotel-big-card {
  width: 100%;
  padding: 20px;

  .hotel-image {
    display: block;
    height: 214px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .stars {
    margin-bottom: 12px;
  }

  .mb-1 {
    display: block;
    margin-bottom: 15px;
  }

  .info-links {
    margin-bottom: 20px;
  }

  .text {
    padding: 20px;
  }

  .t-p {
    margin-bottom: 10px;
  }

  .btn-primary {
    width: 100%;
  }
}
