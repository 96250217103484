@use 'modal_module_nfh_commons' as *;

.modal-module-nfh {
  padding: 0;
  border: 0;

  .wrapper {
    position: relative;
    padding: 0;
  }

  .tabs-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    text-align: center;

    .active-tab {
      @include interactiveElement(false);
      @include fontSize(16);
      @include fontSansBold;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 15px 10px;
      border: 1px solid var(--c_grey_500);
      border-radius: none;

      // I have not found another way to define the family without changing html and js.
      font-family: var(--body-sans) !important;

      &.s-arrow-up,
      &.s-arrow-down {
        &::before {
          margin-left: 10px;
          font-family: iberostar-symbols !important;
        }
      }
    }

    ul {
      display: none;
      border: 1px solid var(--c_grey_500);
      border-top: none;
      background: var(--c_white);

      .tab {
        display: block;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          width: 100%;
          padding: 10px 15px;
        }
      }

      &.visible {
        display: block;
      }
    }

    li {
      &.active {
        @include interactiveElement(false);

        > a::before {
          bottom: 4px;
        }
      }

      &::after {
        display: none;
      }
    }
  }

  .tabs-carousel {
    margin-top: 77px;
  }

  #info-tab {
    padding: 0;
  }

  .content-tab {
    padding: 115px 20px 110px;

    .wrapper-content {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 0;

      figure {
        width: 200px; //remove
        height: 200px; //remove
        margin-top: -50px;
        margin-left: 0;
        margin-bottom: 20px;
      }

      .wrapper-text {
        padding-left: 0;
      }
    }
  }

  .hotel-info-row {
    padding: 20px;
    margin-bottom: 0;

    .title-wrapper {
      flex-direction: column;
    }

    .floating-action {
      position: absolute;
      left: 50%;
      bottom: 10px;
      transform: translateX(-50%);
      width: calc(100% - 40px);

      .btn-floating-fastbooking {
        width: 100%;
      }
    }
  }
}
