@use 'introduction_module_commons' as *;
@use '../../shared/social_list/social_list_mobile';
@use '../../elements/list/list_mobile';

.introduction-module {
  position: relative;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.added-margin-top-mobile {
    margin-top: 70px;
  }

  .textual-content {
    width: inherit;

    .t-label {
      display: block;
    }

    .block-title {
      margin-bottom: 10px;
    }

    .t-p {
      margin-bottom: 5px;
    }

    p {
      &:last-of-type {
        margin-bottom: 24px;
      }

      b,
      strong {
        @include fontSansBold;
      }
    }

    > a[href='#'] {
      position: absolute;
      left: 20px;
      bottom: 0;
      text-align: center;
    }

    .desc {
      margin-bottom: 10px;
      word-wrap: break-word;

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .image {
    width: 100%;
    margin-bottom: 24px;
    overflow: hidden;
    text-align: center;

    picture {
      position: relative;
      width: 100%;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1/1;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .offer-codes {
    margin-top: 40px;

    .offer-code {
      padding: 16px;
      margin: 0 auto 10px;

      &.copied {
        padding-bottom: 50px;

        .success {
          display: block;
        }

        .secondary-btn {
          display: none;
        }
      }
    }

    .t-h3 {
      @include fontSansBold;
      @include fontSize(12);
      color: var(--c_blue_700);
      line-height: 18px;
      letter-spacing: 1.54px;
      text-transform: uppercase;
    }

    .t-p {
      margin-bottom: 16px;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }

    .t-h3 + .t-p {
      margin-top: 8px;
    }

    .t-h3 + .code-number {
      margin-top: 16px;
    }

    .tag-name {
      display: none;
    }

    .t-h3 {
      text-align: center;
    }
  }

  .copy {
    @include fontSize(14);
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    color: var(--c_white);
    font-weight: 700;

    &::after {
      display: block;

      // remove this once we got the timbre icon
      width: 42px;
      height: 42px;
      margin-bottom: 10px;
      background-repeat: no-repeat;
      color: var(--c_white);
      font-size: 42px;
    }
  }

  .layer {
    position: absolute;
    inset: 0;
    display: none;
    align-items: center;
    justify-content: center;

    &.apply-code {
      background: rgba(103, 186, 175, 95%);

      .copy::after {
        content: '';
        width: 24px;
        height: 42px;
        background-image: url('../../../images/icons/ico_timbre.svg');
      }
    }
  }

  .code-applied {
    .s {
      padding: 0 30px;
      margin-top: 90px;
    }
  }

  .secondary-btn {
    width: 100%;
  }

  &.img-to-right {
    .wrapper {
      .offer-codes {
        margin-top: 0;
        margin-bottom: 40px;
      }
    }
  }

  .open-legal {
    position: static;
    text-align: center;
  }

  &.text-centered {
    .textual-content {
      text-align: center;

      .t-p,
      .block-title,
      p {
        text-align: center;
      }

      > a[href='#'] {
        text-align: center;
      }
    }
  }

  &__content {
    &__see-more {
      text-align: center;
    }
  }

  // TODO PY05649-337
  // Alinear solo el título
  &.text-right {
    .textual-content .block-title {
      text-align: right;
    }
  }

  &.w-100 {
    .wrapper {
      position: relative;
      display: block;
      padding-bottom: 48px;
    }

    .textual-content {
      width: 100%;

      > a {
        position: absolute;
        left: 0;
        bottom: 30px;
        right: 0;
        margin: 0 auto;
      }

      .t-p,
      p {
        text-align: center;
      }
    }

    .offer-codes {
      flex: 0 0 100%;
      flex-wrap: wrap;
      width: 100%;
      max-width: 897px;
      margin: 40px auto 0;

      .offer-code {
        margin-bottom: 20px;
      }

      .tag-name {
        width: 66px;
        height: 66px;
        margin: 0 0 16px;

        .t-label {
          margin-bottom: 0;
        }
      }

      .icon {
        font-size: 24px;
      }

      .t-h3 {
        @include fontSansSemiBold;
        @include fontSize(12);
        letter-spacing: 1.54px;
        text-align: left;
        text-transform: uppercase;
      }

      .t-p {
        @include fontSize(14);
        line-height: 24px;
        text-align: left;
      }

      .code-number {
        display: flex;
        padding-left: 8px;

        > .t-fb-label {
          margin-right: 5px;

          &::after {
            content: ':';
          }

          &.code {
            margin: 0;

            &::after {
              content: '';
            }
          }
        }
      }
    }
  }
}
