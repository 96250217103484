@use 'filters_sidebar_commons' as *;
@use '../../elements/destination_index_tabs_nav/destination_index_tabs_nav_mobile';

.mobile-panel.ready {
  &.filters-panel {
    .results-order-by {
      display: none;
    }

    .close-mobile-panel {
      top: 2px;
      color: var(--c_white);
    }

    .panel-title {
      min-height: 45px;
      padding: 14px 30px 14px 20px;
      margin: 0;
      background: var(--c_primary);
      color: var(--c_white);
      font-weight: 600;
      font-size: 14px;
      font-family: var(--font-sans);
      line-height: 17px;
      text-transform: uppercase;
    }

    .filters-sidebar {
      .filters-block {
        border-right: 0;
        border-left: 0;

        .title {
          @include fontSize(16);
          padding-right: 40%;
          line-height: 22px;
        }
      }

      .filters-wrapper {
        min-height: calc(100vh - 115px);

        // padding-bottom: 70px;
      }

      .bottom-actions {
        position: sticky;
        bottom: 0;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 10px;
        margin-top: 0;
        background: var(--c_white);

        .apply-filters-single {
          display: none;
        }

        .clear-all {
          flex: 0 0 auto;
          padding: 14px 18px;
          margin-right: 10px;
          border: 1px solid var(--c_primary);
          background: var(--c_white);
        }

        .apply-filters {
          @include fontSansBold;
          @include fontSize(14);

          @include icon(alert-check, after, 'xs') {
            position: absolute;
            top: 50%;
            left: 12px;
            transform: translateY(-50%);
          }
          position: relative;
          flex: 0 0 auto;
          padding: 15px 19px 15px 37px;
          padding-left: 30px;
          margin-left: 10px;
          background: var(--c_primary);
          color: var(--c_white);
          line-height: 20px;
        }

        &--apply-only {
          .clear-all,
          .apply-filters {
            display: none;
          }

          .apply-filters-single {
            display: block;
          }
        }

        &.hidden {
          display: none;
        }
      }
    }
  }

  &.mobile-filter-panel-dest {
    .filter-dest-panel {
      padding: 20px;
    }

    .secondary-btn {
      margin-left: 20px;
    }
  }
}

.filters-sidebar {
  flex: 0 0 100%;
  width: 100%;

  .hotels-info-with-filtering & {
    display: none;
  }
}
