@use 'faqs_commons' as *;

.with-call-center {
  .faqs-block {
    width: 100%;

    & > .wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.faqs-block {
  .faqs-container {
    .single-faq {
      .faq-question:not(.arrow-up) {
        position: relative;
        padding-right: 30px;
        margin-right: 20px;

        &::after {
          position: absolute;
          right: 0;
          margin-top: 8px;
        }
      }
    }
  }
}

.faqs-block {
  .landing-app {
    .pre-title,
    .block-title {
      text-align: left;
    }
  }
}
