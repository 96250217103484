@use 'home_tabs_item_commons' as *;

.b-home-tabs-container-item {
  display: none;
  width: auto;
  height: auto;
  margin-left: -20px;
  margin-right: -20px;

  .b-home-tabs-item {
    display: flex;
    flex: 0 0 100%;
    align-items: center;
    justify-content: center;

    .block-content {
      z-index: 1;
      flex: 0 0 100%;
      padding: 16px;
      margin: 0 auto;
      background-color: var(--c_white);
      background-size: 350% 350%;

      .block-content-bg {
        border: 16px solid var(--c_white);
        background-color: var(--c_white);

        .block-subtitle {
          margin: 0 0 14px;
          font-size: 12px;
          text-align: left;
        }

        .block-title {
          margin-bottom: 10px;
        }

        .block-desc {
          font-size: 12px;
          line-height: 22px;
        }
      }
    }

    .block-image {
      display: none;
    }
  }
}
