@use '../../../base_css/abstracts/abstracts' as *;
@forward '../../../base_css/abstracts/abstracts';

.information-modules {
  .info-item {
    &__image {
      width: 100%;

      .gallery-wrapper {
        .swiper-pagination {
          position: absolute;
          top: 215px;
          left: 47%;
          transform: translateX(-50%);
          width: fit-content;
        }

        .swiper-button-prev {
          position: absolute;
          top: 40%;
          left: 3%;
        }

        .swiper-button-next {
          position: absolute;
          top: 40%;
          left: 82%;
        }
      }
    }

    .image{
      aspect-ratio: 3/2;
    }
  }
}
