@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.info-item {
  @include shadow-blurred;
  display: flex;
  flex-direction: column;
  align-items: inherit;

  .link-cnt {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: var(--color-body-font) !important;
    font-family: var(--body-font) !important;

  }

  .image {
    img {
      max-width: 100%;
      vertical-align: middle;
    }

    video {
      height: 100%;
      max-height: 262px;
      object-fit: cover;
    }
  }

  .text {
    a {
      font-size: inherit;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
    border: solid 1px var(--c_cloud);
    background-color: var(--c_white);

    .t-highlight {
      color: var(--color-title-font);
      font-family: var(--title-font);
    }
  }

  .action {
    margin-top: auto;
  }

  &.hidden {
    display: none;
  }
}
