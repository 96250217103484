@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.last-search-card .shared,
.share-offer .shared,
.card.hotel .shared {
  @include fontSansBold;

  @include icon(share-circles, after, 's') {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #DCEFED;
    line-height: 32px;
    text-align: center;
  }
  padding: 7px 0 8px;
  border-radius: 16px;
  background: var(--c_blue_green_100);
  color: var(--c_primary);
}

.card.hotel .shared {
  position: absolute;
  bottom: -5px;
  right: 24px;
  z-index: 2;
  width: 32px;
  max-width: 32px;
  height: 32px;
  overflow: hidden;
  font-size: 0;
  line-height: 17px;
  transition: max-width 0.4s ease-in-out, padding 0.4s ease-in-out;

  &:hover {
    @include fontSize(12);
    width: auto;
    max-width: 100%;
    padding: 7px 38px 8px 15px;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@include mobileStyles {
  .card.hotel {
    .action {
      position: relative;
      margin-top: 35px; // prevents crash with tripadvisor widget
    }

    .shared {
      position: static;
      float: left;

      &::after {
        left: 0;
      }
    }
  }
}
