@use 'scrollsnap_slider_commons' as *;
@forward 'scrollsnap_slider_commons';

.ss-slider {
  @include ss-slider;
}

@include mobileStyles {
  .image.carousel-in-modal > .image {
    margin-top: -25px;
  }

  .carousel-in-modal {
    position: relative;
    width: 100%;
    height: 200px;

    .gallery-wrapper {
      margin-top: -25px;

      .carousel-container {
        position: relative;
      }
    }

    picture {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
