@use 'video_module_commons' as *;

.video-module .cover {
  .video-link {
    top: calc(50% + 10px);
  }
}

// Rule for applying a brightness filter to an image
.ada {
  .video_module img {
    filter: brightness(0.7);
  }
}
