@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.offers-index-with-filter {
  .wrapper.flex {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .internal-nav.centered {
    &.swiper-container-horizontal {
      ul {
        justify-content: flex-start;
      }
    }

    ul {
      li {
        &::after {
          height: 26px;
        }

        a {
          @include link-base;
          padding: 19px 14px;
          margin: auto $space2;
          color: var(--interactive-text-base);

          &::after {
            bottom: 9px;
          }

          &:hover {
            color: var(--c_blue_700);
          }
        }

        &.current {
          a {
            color: var(--c_blue_700);
          }
        }
      }
    }

    .offer-cat-next {
      @include icon(arrow-right-fat, after, 'xs') {
        position: absolute;
        top: 50%;
        right: 13px;
        transform: translateY(-50%);
        color: var(--c_primary);
      }
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 45px;
      height: 100%;
      background-image: linear-gradient(to left, var(--c_blue_green_100), rgba(var(--c_turquoise_secondary_rgb), 0.5));

      &.swiper-button-disabled {
        opacity: 0.3;
      }
    }

    .offer-cat-prev {
      @include icon(arrow-left-fat, after, 'xs') {
        position: absolute;
        top: 50%;
        left: 13px;
        transform: translateY(-50%);
        color: var(--c_primary);
      }
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 45px;
      height: 100%;
      background-image: linear-gradient(to right, var(--c_blue_green_100), rgba(var(--c_turquoise_secondary_rgb), 0.5));

      &.swiper-button-disabled {
        opacity: 0.3;
      }
    }
  }
}
