@use '../../../src/base_css/abstracts/abstracts' as *;
@forward '../../../src/base_css/abstracts/abstracts';

$li_line_height: 24px;
$li_before_size: 10px;
$li_top_position: ($li_line_height - $li_before_size) * 0.5;

.list-styles {
  ul {
    li {
      position: relative;
      padding-left: $li_before_size + 16px;
      margin-bottom: 10px;

      &::before {
        position: absolute;
        top: $li_top_position;
        left: 0;
        content: '';
        width: $li_before_size;
        height: $li_before_size;
        background-color: var(--c_grey_200);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {
    counter-reset: section;

    li {
      position: relative;
      padding-left: $li_before_size + 16px;
      margin-bottom: 10px;

      &::before {
        @include fontSansSemiBold;
        position: absolute;
        top: 0;
        left: 0;
        content: counters(section, '.') ' ';
        counter-increment: section;
      }
    }
  }
}

.icon-list {
  $self: &;

  &__item {
    position: relative;
    padding-left: $li_before_size + 16px;
    margin-bottom: 8px;

    &::before {
      position: absolute;
      top: $li_top_position;
      left: 0;
      content: '';
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--check {
    #{ $self }__item {
      @include icon(check, before) {
        color: var(--c_green);
      }
    }
  }
}
