@use 'information_modules_commons' as *;

.modal.is-open {
  .carousel-videos {
    .modal__content {
      display: flex;
      align-items: center;

      // Crop the header size
      height: calc(100% - 82px);
      padding: 0;

      .swiper-button-prev,
      .swiper-button-next {
        // Place with half of the header
        top: calc(100% - 41px);
      }

      .swiper-button-prev {
        left: 20px;
      }

      .swiper-button-next {
        right: 20px;
      }
    }

    .swiper-carousel-video {
      // Visual centring of the video. Subtracting half of the header
      top: -41px;
    }
  }
}
