@use './shared_block_commons' as *;

@include commonTabletDesktop {
  .shared-block {
    .form-cnt {
      display: none;
    }

    .social-share-list {
      width: 55%;
      padding: 55px 40px 40px;

      .form-title {
        margin-bottom: 30px;
        color: var(--c_blue_700);
        font-size: 24px;
        font-family: var(--font-serif);
        line-height: 26px;
      }

      .share-container {
        .share {
          display: flex;
          align-items: center;
          color: var(--c_turquoise);
          font-weight: 700;
          font-size: 16px;
          font-family: var(--font-sans);
          line-height: 20px;
          letter-spacing: 0;
          text-align: center;

          * {
            pointer-events: none;
          }

          .s {
            @include fontSize(22);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            height: 42px;
            margin-right: 10px;
            border: 2px solid var(--c_turquoise);
            border-radius: 100%;
          }

          .share-text {
            position: relative;

            &::before {
              position: absolute;
              left: 0;
              bottom: 0;
              transform: translateY(9px);
              content: '';
              opacity: 0;
              width: 100%;
              height: 2px;
              background: var(--c_turquoise);
              transition: transform 0.3s, opacity 0.3s;
              pointer-events: none;
            }
          }

          &:hover,
          &:focus {
            .share-text {
              &::before {
                transform: translateY(4px);
                opacity: 1;
              }
            }
          }

          &:active {
            .share-text {
              color: var(--c_dark_grey);

              &::before {
                background: var(--c_cloud);
              }
            }
          }
        }
      }
    }
  }
}
