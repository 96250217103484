@use 'footer_newsletter_commons' as *;

$s-s: 16px;
$s-m: 20px;
$s-l: 40px;
$s-xl: 60px;

.footer-newsletter {
  padding: $s-m;
  margin-bottom: $s-xl;

  .image {
    display: none;
  }

  .content {
    padding: $s-m $s-m $s-m;
  }

  .t-h3 {
    margin-top: 4px;
    margin-bottom: 6px;
  }

  .t-p {
    margin-bottom: $s-s;
  }

  .fz-col {
    margin-top: $s-m;

    &.newsletter-privacy-check {
      margin-top: $s-s;
    }
  }

  &.logged {
    .content {
      padding-bottom: 24px;
    }
  }
}
