@use '../../../src/base_css/abstracts/abstracts' as *;
@forward '../../../src/base_css/abstracts/abstracts';
@use '../../elements/scrollsnap_slider/scrollsnap_slider';

.best-offers-block {
  $bg_green_gap: 30px;
  $image_height: 200px;

  @include swiperPaginationCustom;

  &.e-skeleton {
    background: none;
  }

  .flex {
    justify-content: flex-start;
    padding: 0;

    > * {
      position: relative;
      display: flex;
      flex: 0 0 100%;
      flex-wrap: wrap;
      width: 100%;
      max-width: 315px;
      margin-right: $gutter;

      &:last-child {
        margin-right: 0;
      }

      img {
        display: block;
        width: 100%;
      }

      .content {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 15px 24px;
        border: 1px solid var(--c_grey_200);
        background: var(--c_white);

        > .t-label,
        > .t-highlight {
          flex: 0 0 100%;

          .stars {
            display: inline-block;
          }
        }

        > .badges-list {
          display: flex;
          flex: 0 0 100%;
          flex-wrap: wrap;
          margin-bottom: 4px;

          .badge {
            margin-right: 8px;
          }
        }

        &::before {
          position: absolute;
          left: 8%;
          bottom: 0;
          z-index: -1;
          content: '';
          opacity: 0;
          width: 84%;
          height: 10px;
          max-height: 30%;
          box-shadow: 0 1px 38px -1px rgba(0, 0, 0, 60%);
          background-color: rgba(0, 0, 0, 24%);
          transition: opacity 0.4s ease-in-out;
        }

        &:hover,
        &:focus {
          &::before {
            opacity: 1;
          }
        }
      }

      .bottom-content {
        display: flex;
        flex: 0 1 100%;
        justify-content: space-between;
        margin-top: auto;

        > * {
          flex: 0 1 auto;
        }

        .t-label {
          color: var(--color-body-font);
        }

        .price-content {
          align-self: flex-end;
          text-align: right;

          .legend {
            display: inline-block;
          }
        }

        .price-cnt {
          display: inline-block;
          color: var(--c_black_grey_700);
        }

        .fake-link {
          z-index: 1;
          align-self: flex-end;
        }

        .after-price {
          @include fontSansSemiBold;
          @include fontSize(12);
          line-height: 18px;
        }
      }
    }
  }

  .link-cnt {
    display: flex;
    flex: 1 1 100%;
    flex-wrap: wrap;
    color: inherit;
    font: inherit;

    &.hidden {
      display: none;
    }

    .image {
      flex: 0 0 100%;
    }

    .content {
      position: static;
      flex: 0 1 100%;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }

  .offers-cnt {
    @include bg-half(calc( 100% - #{$bg_green_gap} - #{$image_height} ));
    position: relative;
    padding: 0 20px 14px;
  }

  .before-price {
    display: flex;
    justify-content: flex-end;

    > * {
      flex: 0 0 auto;

      &:first-child {
        margin-right: 5px;
      }
    }

    .with-line {
      position: relative;

      &::after {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        content: '';
        width: 100%;
        height: 1px;
        background: var(--c_carrot);
      }
    }

    p,
    .t-coin {
      @include fontSansSemiBold;
      @include fontSize(12);
      color: var(--c_carrot);
      line-height: 18px;
    }

    .t-price {
      @include fontSize(12);
      line-height: initial;
    }
  }
}
