@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.hotel-collapsible {
  $self: &;
  border-top: 1px solid var(--c_grey_200);

  &--woc {
    background-color: var(--background-color);

    #{ $self }__summary-button {
      border: solid 2px var(--c_white);
      background-color: var(--background-color);
      color: var(--c_white);
    }

    .hotel-woc__header {
      color: var(--c_white);
    }
  }

  &:not(:has(+ .hotel-collapsible)) {
    margin-bottom: 60px;
    border-bottom: 1px solid var(--c_grey_200);
  }

  &__summary {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-header {
      display: flex;
      flex-direction: column;
      width: 80%;

      &--active {
        opacity: 0;
      }
    }

    &-title {
      @include fontSerifSemiBold;
      align-items: center;
      color: var(--color-title-font);
    }

    &-description {
      align-items: center;
    }

    &-button {
      position: relative;
      cursor: pointer;
      border: 1px solid var(--swiper-navigation-border-color);
      border-radius: 100%;
      background: var(--c_white);
      color: var(--swiper-theme-color);
      text-align: center;
      pointer-events: none;
      inset: auto;

      &--active {
        transform: rotate(180deg);
        background: var(--swiper-theme-color);
        color: var(--c_white);
      }
    }
  }

  summary {
    list-style: none; /* Remove the default triangle */
  }

  summary::-webkit-details-marker {
    display: none; /* Removing the triangle in WebKit-based browsers (Safari, Chrome) */
  }

  &__content-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;

    .hotel-collapsible__content-title {
      @include fontSize(48);
      @include fontSerifSemiBold;
      color: var(--btn-label-color-negative-primary);
      line-height: 60px;
      text-align: center;
      margin-top: 15px;
    }

    .hotel-collapsible__content-description {
      @include fontSize(18);
      text-align: center;
    }
  }

  .block-title-header {
    display: none;
  }
}
