@use 'information_horizontal_module_commons' as *;
@use '../../elements/scrollsnap_slider/scrollsnap_slider_mobile';
@use '../../shared/elements/embedded_video/embedded_video_mobile';

.background-information-horizontal-module {
  padding: 0;

  .wrapper {
    padding: 10px 0;
  }

  .block-title {
    padding: 10px 0 0 20px;
    margin: 0;
  }
}

.information-horizontal-module {
  padding: 0 15px;
  margin: 20px auto 40px;

  .ss-slider {
    padding-right: 40px;

    &.no-scroll {
      padding-right: 0;
      margin: 0 20px;
    }

    .ss-slide {
      min-width: 100%;
      margin-right: 20px;
    }
  }

  .information-horizontal-module-li {
    padding-bottom: 20px;

    &:nth-child(even) {
      .horizontal-card {
        &.info {
          &.information-horizontal-module-item {
            .card-image {
              order: 0;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.horizontal-card.info {
  margin: 0 0 10px;

  &,
  &:hover {
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 14%);
  }

  .card-image {
    position: static;
    float: none;
    width: 100%;
  }

  .card-container {
    width: 100%;
    height: auto;
    padding: 10px 10px 15px;
    margin: 0;
    background: var(--c_white);

    &.untruncated {
      padding-bottom: 15px;

      .l-down {
        padding: 0 20px 0 0;
      }
    }
  }

  .desc {
    padding-bottom: 0;

    p {
      margin: 5px 0;
    }
  }

  .l-down {
    position: relative;
    padding: 0 20px 0 0;
  }

  &.information-horizontal-module-item {
    flex-direction: column;
    height: 475px;
    margin: 0;

    &::after {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      content: '';
      width: 100%;
      height: 45px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0%) 0%, rgba(255, 255, 255, 100%) 100%);
      pointer-events: none;
    }

    .card-image {
      flex: 0 0 200px;
      height: 200px;
      margin: 0;

      .e-image-counter {
        background: rgba(0, 0, 0, 50%);
      }

      picture {
        width: 100%;
        height: 100%;

        img {
          position: relative;
          top: 0;
          left: 0;
          transform: translate(0, 0);
          width: 100%;
          min-width: 0;
          height: 100%;
          min-height: 0;
        }
      }
    }

    .card-container {
      flex: 0 1 100%;
      padding: 0 20px;
      margin: 20px 0;
      overflow: auto;

      &::after {
        display: none;
      }
    }

    .title {
      padding-bottom: 0;
      font: bold 14px/17px var(--font-serif);
    }

    .desc {
      flex: 0 0 auto;
      width: 100%;
      padding: 0;
      font: 500 14px/20px var(--font-sans);

      p {
        @include fontSize(14);
        margin: 10px 0 0;
        line-height: 24px;
      }

      ul {
        margin: 10px 0 0;

        li {
          font-size: 14px;
          line-height: 20px;

          &::after {
            font-size: 15px;
          }
        }
      }
    }

    &.trunked {
      .card-container {
        &::after {
          display: block;
          height: 42px;
        }
      }

      .action {
        position: absolute;
        bottom: 15px;
        display: block;
        padding: 0 20px;

        a {
          @include icon(arrow-down, after, 'xs') {
            position: absolute;
            top: 8px;
            right: 0;
          }
          position: relative;
          display: inline-block;
          padding-right: 18px;
          font-size: 14px;
          line-break: 17px;

          .less {
            display: none;
          }
        }
      }

      &.opened {
        height: auto;

        .card-container {
          flex: 0 0 auto;

          &::after {
            display: none;
          }
        }

        .action {
          a {
            @include icon(arrow-up);

            .more {
              display: none;
            }

            .less {
              display: block;
            }
          }
        }
      }
    }
  }
}

.horizontal-card-swiper {
  margin-bottom: 30px;

  .swiper-wrapper {
    height: auto;
  }
}
