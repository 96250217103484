@use 'offers_index_with_filter_commons' as *;
@use '../../shared/internal_nav/internal_nav_mobile';

.offers-index-with-filter {
  .internal-nav {
    display: none;
  }

  .mobile-offers-filter-link {
    @include link-base;

    @include icon(filter, after, 'm') {
      position: absolute;
      top: 50%;
      left: 24px;
      transform: translateY(-50%);
    }
    display: block;
    padding: 10px 10px 10px 56px;
    margin-bottom: 20px;
    background: var(--c_blue_green_100);

    &::before {
      display: none;
    }
  }
}

.js-offer-filter-mobile {
  padding: 15px;

  li {
    display: block;

    a {
      @include fontSize(16);
      position: relative;
      cursor: pointer;
      display: block;
      padding: 5px 10px 5px 31px;
      color: var(--c_dark_grey);
      line-height: 26px;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        content: '';
        width: 20px;
        height: 20px;
        border: 1px solid var(--c_dark_grey);
        border-radius: 50%;
      }
    }

    &.current {
      a {
        &::before {
          border-color: var(--c_green);
        }

        &::after {
          position: absolute;
          top: 50%;
          left: 3px;
          transform: translateY(-50%);
          content: '';
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: var(--c_green);
        }
      }
    }
  }
}

#mobile-js-offer-filter {
  .bottom-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    margin-top: 0;
    text-align: right;

    .clear-all {
      display: inline-block;
      padding: 0;
      margin: 0;
      border: 0;
    }
  }
}
