@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.with-call-center {
  display: flex;
  flex-wrap: wrap;

  .faqs-block {
    width: 50%;

    & > .wrapper {
      padding-left: 0;
      padding-right: 12px;
    }

    .faqs-container {
      margin-right: 0;
    }
  }
}

.faqs-block {
  margin-top: 40px;

  .faqs-container {
    margin-right: 12px;
    border: 1px solid var(--c_grey_200);

    .single-faq {
      cursor: pointer;
      border-bottom: 1px solid var(--c_grey_200);

      .faq-answer {
        cursor: initial;
        display: none;
        padding: 0 16px 16px;

        p {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0;
        }
      }

      .faq-question {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;

        &:not(.arrow-up) {
          @include icon(arrow-down-fat, after, 'xs') {
            margin-left: 8px;
            color: var(--color-link-primary-hover);
            font-weight: 900;
          }
        }

        &.arrow-up {
          @include icon(arrow-up-fat, after, 'xs') {
            margin-left: 8px;
            color: var(--color-link-primary-hover);
            font-weight: 900;
          }
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .my-iberostar-page & {
    width: calc(100% - 24px);
    padding: 20px 0;
    margin-left: 24px;
    box-shadow: 0 2px 4px 0 var(--c_grey_200);
    background: var(--c_white);
  }
}

.faqs-block {
  .landing-app {
    .pre-title,
    .block-title {
      text-align: center;
    }

    .faqs-container {
      border: 0;

      .single-faq {
        &:first-child {
          border-top: 1px solid var(--c_grey_200);
        }

        &:last-child {
          border-bottom: 1px solid var(--c_grey_200);
        }
      }
    }
  }
}
