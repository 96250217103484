@use 'best_offers_block_commons' as *;
@use '../../elements/block_title_header/block_title_header_mobile';
@use '../../elements/badge/badge_mobile';
@use '../../elements/scrollsnap_slider/scrollsnap_slider_mobile';

.best-offers-block {
  overflow: hidden;

  .flex {
    > * {
      height: auto;
    }
  }

  .image {
    min-height: 208px;
  }

  .e-skeleton-content {
    .bg-mask {
      &.row1 {
        height: 10px;
        margin-top: 20px;
      }

      &.row2 {
        height: 16px;
        margin-top: 20px;
      }

      &.row3 {
        width: 78%;
        height: 20px;
      }

      &.row4 {
        width: 70%;
        height: 22px;
      }

      &.row5 {
        width: 40%;
        height: 23px;
        margin-left: 30%;
      }

      &.row-thin-separator {
        height: 3px;
      }
    }
  }
}
