@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.filters-sidebar {
  border-top: 1px solid var(--c_grey_200);

  .filters-block {
    border: 1px solid var(--c_grey_200);
    border-top: 0;

    > * {
      position: relative;
      z-index: 2;
    }

    &.destinations {
      background: #000;
    }

    .title {
      @include fontSansSemiBold;
      @include fontSize(18);

      @include icon(arrow-down-fat, after, 'xs') {
        position: absolute;
        top: 24px;
        right: 16px;
        color: var(--c_primary);
      }
      position: relative;
      cursor: pointer;
      display: block;
      max-height: 1000px;
      padding: 16px 26px 16px 15px;
      color: var(--c_dark_grey);
      line-height: 26px;
      transition: background-color 0.3s ease, color 0.3s ease;

      span {
        @include fontSansRegular;
        @include fontSize(16);
        opacity: 1;
        display: block;
        max-height: auto;
        margin-top: 5px;
        line-height: 24px;
        transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
      }

      &::before {
        position: absolute;
        top: -1px;
        left: -1px;
        content: '';
        opacity: 0;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 1px solid var(--c_primary);
        transition: opacity 0.3s ease;
      }

      &.active {
        @include icon(arrow-up-fat);
        background-color: var(--c_blue_green_100);

        span {
          opacity: 0;
          max-height: 0;
        }
      }
    }

    .filters {
      z-index: 9;
    }

    .filters,
    .filters-index {
      display: block;
      padding: 15px;

      &.destinations {
        display: block;
      }

      .t-label {
        margin: 13px 0;

        &:first-child {
          margin-top: 0;
        }
      }

      label {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .s {
          position: relative;
          top: -3px;
          display: inline-block;
          margin-right: 3px;
          font-size: 16px;
        }
      }
    }
  }

  .fk-check {
    .hotel-count {
      display: none;
    }

    &.with-counter {
      .hotel-count {
        display: inline;
      }
    }
  }

  &.formikation {
    label.fk-check {
      &.checked {
        @include icon(check);

        &::before {
          border-color: var(--c_green);
          background: var(--c_white);
          font-weight: 600;
        }

        &::after {
          top: 8px;
          left: 5px;
          color: var(--c_green);
          font-size: 11px;
        }
      }

      &.disabled {
        pointer-events: all;
      }

      &.select-all {
        @include fontSansSemiBold;
        color: var(--c_primary);

        &::before {
          border-color: var(--c_primary);
        }

        &.checked {
          &::before {
            border-color: var(--c_green);
          }
        }
      }
    }
  }

  .info {
    @include icon(alert-info) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--c_primary);
      font-size: 9px;
    }
    position: relative;
    top: 5px;
    left: 3px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--c_blue_green_100);
  }

  .bottom-actions {
    margin-top: 15px;

    .clear-all {
      @include icon(paper-bin, before, 's');
    }

    .apply-filters-single {
      display: none;
    }
  }

  &.loading {
    pointer-events: none;
  }

  .fieldset-no-style {
    padding: 0;
    margin: 0;
    border: 0;
  }
}

// TEST 307115
// CSS from .filters-sidebar (up in this .scss page) is included, so it can be safely deleted
.filters-sidebar.theme-plus-sign {
  .filters-wrapper {
    border: 1px solid rgba(227, 233, 245, 50%);
  }

  .filters-block {
    padding: 0;
    overflow: hidden;
    border-right: 0;
    border-bottom: 1px solid var(--c_grey_200);

    .title {
      color: var(--c_black);
    }

    &:last-child {
      border-bottom: 0;
    }

    &.subfilters-block {
      background: var(--c_background);

      .title {
        padding: 10px 20px;
      }
    }
  }

  .fk-check {
    .hotel-count {
      display: inline;
    }
  }

  .title,
  .filters {
    padding: 20px;
  }

  .title {
    @include icon('plus', after, 'xs') {
      float: right;
      margin: 0;
      color: var(--c_primary);
      transition: transform 0.3s ease;
    }
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0;

    &.active {
      @include icon('minus') {
        position: static;
        transform: rotate(180deg);
      }
    }
  }

  .filters {
    background: var(--c_background);

    &.with-subfilters {
      padding: 0;
    }

    .fk-check {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .clear-all {
    @include icon(paper-bin, before, 'm');
    display: block;
    float: right;
  }

  label {
    &.fk-check {
      padding-left: 26px;

      &::before {
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
  }
}
