@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.e-image-counter {
  position: absolute !important;
  top: 8px;
  right: 8px;
  z-index: 3 !important;
  width: 32px;
  height: 32px;
  padding: 7px 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 20%);
  color: var(--c_white);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
