@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.fidelization-info {
  .block-content {
    position: relative;
    display: flex;
  }

  .block-container {
    z-index: 1;
    width: 100%;
    max-width: 783px;
    height: 100%;
    padding: 30px 40px;
    margin: 25px 0;
    box-shadow: 10px 10px 24px rgba(0, 0, 0, 10%);
    background: var(--c_white);

    .pre-title {
      margin-bottom: 20px;
      letter-spacing: 0;
    }

    .block-title {
      @include fontSize(24);
    }

    .block-description {
      &.has-button {
        margin-bottom: 30px;
      }
    }

    .horizons-links-content {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .link-container {
        display: flex;
        padding: 0 $space4;
      }
    }

    .block-link {
      display: inline-block;
      padding: 15px 46px;
    }

    ul {
      li {
        @include icon(check, before, 's') {
          margin-right: 17px;
          color: var(--c_green);
          line-height: 24.51px;
        }
      }
    }
  }

  .block-image {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;

    .image {
      min-width: 571px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.content-fidelization-info-fancybox {
  max-width: 500px;
  padding: 20px;
}
