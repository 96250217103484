@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.block-header-title {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: em(40);

  &__label {
    @include fontSize(12);
    margin-bottom: 0;
    font-weight: var(--sans-semi-bold);
    text-transform: uppercase;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__icon {
    display: inline-block;
    font-size: em(25);
  }

  .block-title {
    margin: 0;
  }
}

@include media($tablet) {
  .block-header-title {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: em(20);

    &__link {
      @include fontSize(14);
    }

    &__icon {
      display: none;
    }

    .block-title {
      margin: 0;
    }
  }
}
